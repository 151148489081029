import React, { useState, useEffect } from 'react';
import { Dropdown, TightRows } from '../Shared/StyledComponents';

const DropdownWithOther = ({ currentValue, options, onChange }) => {
  const [otherValue, setOtherValue] = useState('');

  useEffect(() => {
    if (!options.includes(currentValue) && currentValue !== 'other') {
      setOtherValue(currentValue);
    }
  }, [currentValue, options]);

  const handleChange = (event) => {
    const newValue = event.target.value;

    if (newValue !== 'other') {
      onChange(newValue === 'true' ? true : newValue === 'false' ? false : newValue);
      setOtherValue(''); // Reset other value if not selecting "Other"
    } else {
      onChange(newValue);
    }
  };

  const handleOtherChange = (event) => {
    const newValue = event.target.value;
    setOtherValue(newValue);
    onChange(newValue);
  };

  return (
    <TightRows>
      <Dropdown className="full" value={options.includes(currentValue) ? currentValue : 'other'} onChange={handleChange}>
        {options.map((option, index) => (
          <option key={index} value={String(option)}>
            {String(option)}
          </option>
        ))}
        <option value="other">Other</option>
      </Dropdown>
      {(!options.includes(currentValue) || currentValue === 'other') && (
        <input className="input full" type="text" value={otherValue} onChange={handleOtherChange} placeholder="Enter other option" />
      )}
    </TightRows>
  );
};

export default DropdownWithOther;

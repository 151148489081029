import { Controller } from '@hotwired/stimulus';
import { init_codemirror_json } from '../components/codemirror';

// Connects to data-controller="media-data-table"
export default class extends Controller {
  static values = { url: String };
  static targets = ['table', 'detail'];

  bytes_to_human = (bytes, precision = 2) => {
    if (!+bytes) return '0 bytes';
    if (bytes === 1) return '1 byte';
    const label = ['bytes', 'KB', 'MB', 'GB'];
    const value = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${parseFloat((bytes / Math.pow(1024, value)).toFixed(precision))} ${label[value]}`;
  };

  seconds_to_human = (seconds) => {
    seconds = Number(seconds);
    const d = Math.floor(seconds / (3600 * 24));
    const h = Math.floor((seconds % (3600 * 24)) / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);

    const label = [];
    if (d > 0) label.push(`${d} ${d === 1 ? ' day' : ' days'}`);
    if (h > 0) label.push(`${h} ${h === 1 ? ' hour' : ' hours'}`);
    if (m > 0) label.push(`${m} ${m === 1 ? ' min' : ' mins'}`);
    if (s > 0) label.push(`${s} ${s === 1 ? ' sec' : ' secs'}`);

    return label.join(', ');
  };

  build_row({ parent, toggle, key_label, value_label, row_class, key_class, value_class } = {}) {
    const row = document.createElement('div');
    (parent || this.tableTarget).appendChild(row);
    row.className = `row ${row_class || ''}`;

    const key = document.createElement('div');
    row.appendChild(key);
    key.className = `key ${key_class || ''}`;
    key.innerText = key_label || 'key';

    const value = document.createElement('div');
    row.appendChild(value);
    value.className = `value ${value_class || ''}`;
    value.innerText = value_label || 'value';

    if (toggle) {
      toggle = document.createElement('div');
      row.appendChild(toggle);
      toggle.className = 'toggle';
      toggle.dataset.toggleSectionTarget = 'toggle';
    }
  }

  build_codemirror({ parent, data, label, collapsed }) {
    const is_raw = typeof data === 'object';

    this.build_row({
      parent,
      toggle: collapsed,
      row_class: 'header',
      key_label: label || 'value',
      value_label: this.bytes_to_human((is_raw ? JSON.stringify(data || '') : data.toString()).length),
    });

    const row = document.createElement('div');
    (parent || this.tableTarget).appendChild(row);
    row.className = is_raw ? 'row codemirror' : 'row';
    if (collapsed) row.dataset.toggleSectionTarget = 'section';

    if (is_raw) {
      const textarea = document.createElement('textarea');
      row.appendChild(textarea);
      textarea.dataset.readonly = true;
      textarea.value = JSON.stringify(data, null, 2);
      init_codemirror_json(textarea, { wordwrap: false });
    } else {
      const cell = document.createElement('div');
      row.appendChild(cell);
      cell.className = 'value';
      cell.innerText = data;
    }
  }

  async connect() {
    if (this.element.dataset.initialized) return;
    this.element.dataset.initialized = true;

    if (!this.urlValue || !this.hasTableTarget) return;
    const response = await fetch(this.urlValue, { cache: 'no-cache' });
    const data = response.ok ? await response.json() : {};

    this.tableTarget.dataset.controller = 'toggle-section';

    if (this.element.dataset.key === 'node_api_request' || this.element.dataset.key === 'node_api_response') {
      const parent = document.createElement('div');
      this.tableTarget.appendChild(parent);
      parent.dataset.toggleSectionTarget = 'section';

      if (data.headers) {
        const headers = Object.entries(data.headers);

        const headers_parent = document.createElement('div');
        parent.appendChild(headers_parent);
        headers_parent.dataset.controller = 'toggle-section';

        this.build_row({
          parent: headers_parent,
          toggle: true,
          row_class: 'subheader',
          key_label: headers.length === 1 ? 'header' : 'headers',
          value_label: `${headers.length} ${headers.length === 1 ? 'entry' : 'entries'}`,
        });

        const headers_section = document.createElement('div');
        headers_parent.appendChild(headers_section);
        headers_section.dataset.toggleSectionTarget = 'section';

        headers.forEach((header) => {
          this.build_row({ parent: headers_section, toggle: true, key_class: 'http-header', key_label: header[0], value_label: header[1] });
        });
      }

      if ('payload' in data) {
        this.build_codemirror({ parent, data: data.payload, label: 'payload', collapsed: true });
      }
    }

    if (this.element.dataset.key.endsWith('_output') || this.element.dataset.key.endsWith('console_json')) {
      this.tableTarget.dataset.controller = 'toggle-section';
      this.build_codemirror({ data, collapsed: !this.element.dataset.key.endsWith('console_json') });
      this.tableTarget.style.display = null;
    }

    if (this.element.dataset.key.startsWith('node_session')) {
      if (data.ttl) {
        this.detailTarget.innerText = this.seconds_to_human(data.ttl);
        this.detailTarget.parentElement.style.display = null;
      }

      if ('value' in data) {
        this.tableTarget.dataset.controller = 'toggle-section';
        this.build_codemirror({ data: data.value, collapsed: true });
        this.tableTarget.style.display = null;
      }
    }

    if (this.element.dataset.key == 'system_status_started') {
      data.memory_dump ||= {};
      if (!data.memory) {
        data.memory = Object.fromEntries(
          Object.entries(data.memory_dump.custom || {})
            .filter(([_, v]) => !v.expired)
            .map(([k, v]) => [k, v.value]),
        );
      }

      if (!data.session_data) {
        data.session_data = Object.fromEntries(
          Object.entries(data.memory_dump.session_data || {})
            .filter(([_, v]) => !v.expired)
            .map(([k, v]) => [v.user_key || k, v.value]),
        );
      }

      delete data.memory_dump;

      this.build_codemirror({ data, label: 'request', collapsed: true });
      this.tableTarget.style.display = null;
    } else if (this.element.dataset.key.startsWith('system_status')) {
      this.build_codemirror({ data, label: 'error' });
      this.tableTarget.style.display = null;
    }
  }
}

import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="input-prefix"
export default class extends Controller {
  static targets = ['input', 'prefix'];

  connect() {
    this.prefixTarget.innerText = this.prefixTarget.dataset.prefix;

    let width = this.prefixTarget.offsetWidth;
    width += 12;
    this.inputTarget.style.paddingLeft = `${width}px`;

    this.prefixTarget.addEventListener('click', () => this.inputTarget.focus());
  }
}

import React from 'react';

import styled from 'styled-components';

const Button = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
`;

const ToggleArrow = ({ active = false, onClick, label }) => {
  let rotationStyle = {
    transition: 'transform 0.2s',
    transform: active ? 'rotate(0deg)' : 'rotate(-90deg)',
  };
  return (
    <Button onClick={onClick}>
      <span type="button" style={rotationStyle}>
        {/* TODO: move to .svg file */}
        <svg width="6" height="5" viewBox="0 0 6 5" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3 4.5L5.59808 0H0.401924L3 4.5Z"
            fill="#9D9FA1"
            style={{ fill: 'color(display-p3 0.6157 0.6235 0.6314)', fillOpacity: 1 }}
          />
        </svg>
      </span>
      {label}
    </Button>
  );
};

export default ToggleArrow;

import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="sticky-hide"
export default class extends Controller {
  static targets = ['button'];
  static values = { id: String };

  connect() {
    this.buttonTarget.addEventListener('click', (e) => this.hide(e));
    if (!!localStorage.getItem(this.key)) {
      this.hide();
    } else {
      this.show();
    }
  }

  get key() {
    return `sticky-hide-${this.idValue}`;
  }

  hide() {
    this.element.style.display = 'none';
    localStorage.setItem(this.key, true);
  }

  show() {
    this.element.style.display = null;
    localStorage.removeItem(this.key);
  }
}

import styled from 'styled-components';

export const BaseNodeWrapper = styled.div`
  background: var(--color-grey-10);
  border-radius: var(--node-border-radius);
  position: relative;
  font: normal 11px var(--font-monospace);
  cursor: default;
  box-shadow: var(--shadow-normal);
  width: var(--node-width);

  .title {
    font-size: 12px;
    font-family: var(--font-body);
  }
`;

export const EventSplitRow = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  & > * {
    flex-grow: 1;
    width: 100%;
  }
`;

export const NodeContent = styled.div`
  padding-top: 12px;
  padding-bottom: 12px;
  background: var(--color-grey-10);
  border-bottom-left-radius: var(--node-border-radius);
  border-bottom-right-radius: var(--node-border-radius);
`;

export const LabelAndField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  &.lg {
    gap: 12px;
  }

  .title {
    font-size: 12px;
  }

  input,
  select {
    align-self: flex-start;
  }
`;

export const RowsWithDividers = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
  & > * {
    border-top: 1px solid var(--color-grey-20);
    padding-top: 12px;
    margin-top: 12px;
    &:first-child {
      border-top: none;
      padding-top: 0;
      margin-top: 0;
    }
  }
`;

export const PaddedGroup = styled.div`
  position: relative;
  padding-left: var(--h-padding);
  padding-right: var(--h-padding);
`;

export const TightRows = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
`;

export const CodeSnippet = styled.div`
  position: relative;
  height: 60px;
  width: 100%;
  padding: 8px;
  font: var(--weight-medium) 9px var(--font-monospace);
  white-space: pre;
  overflow: hidden;
  background: var(--color-grey-20);
  color: var(--color-grey-70);
  cursor: pointer;

  &.wrap {
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }

  &::placeholder,
  &.placeholder {
    color: var(--color-grey-50);
  }

  .icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: center center no-repeat var(--color-white);
    border-radius: var(--node-border-radius);
    border: 1px solid var(--color-grey-30);
    width: 18px;
    height: 18px;
  }
`;

export const Buttonish = styled.button`
  border-radius: var(--node-border-radius);
  padding: 3px 8px 4px 8px;
  background-color: var(--color-navy-70);
  font-family: var(--font-monospace);
  color: var(--color-grey-20);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 9px;
  cursor: pointer;
  font-weight: var(--weight-medium);

  &:hover {
    background-color: var(--color-navy-80);
  }

  &.textarea-label-decorative {
    background-color: var(--color-grey-60);
    position: absolute;
    bottom: 8px;
    right: 8px;
  }

  i {
    display: block;
    margin-right: 2px;
    height: 12px;
    width: 12px;
    background-size: 11px 11px;
    background-repeat: no-repeat;
    background-position: center;
  }
`;

export const Header = styled.div`
  background: var(--node-title-fill-color);
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-family: var(--font-body);
  align-items: center;
  height: 32px;
  position: relative;
  border-top-left-radius: var(--node-border-radius);
  border-top-right-radius: var(--node-border-radius);

  input {
    cursor: text;
    color: var(--node-title-text-color);

    &:read-only {
      cursor: inherit;
    }

    &:focus {
      color: var(--node-title-input-text);
      background: var(--node-title-input-fill);
    }

    &::placeholder {
      color: var(--node-title-text-color-placeholder);
    }
  }
`;

export const Dropdown = styled.select`
  &.full {
    width: 100%;
  }

  cursor: default;
  appearance: none;
  -webkit-appearance: none;
  text-wrap: nowrap;
  background: center right 5px no-repeat var(--color-grey-20) url('/assets/icons/dropdown-arrow.svg');
  padding: 8px 15px 8px 8px;
  font-weight: var(--weight-medium);
  font-size: 11px;
  color: var(--color-grey-100);

  &.transparent {
    font-size: 13px;
    color: var(--color-grey-100);
    background-color: transparent;
    padding: 0 16px 0 0;
    &:focus {
      outline: 0;
    }
  }

  &.secondary {
    color: var(--color-grey-60);
    font-size: 11px;
  }

  &.no-arrow {
    background-image: none;
  }
`;

export const TwoThirdsRow = styled.div`
  align-items: center;
  grid-template-columns: 1fr 3fr;
  display: grid;

  label {
    color: var(--color-grey-60);
  }
`;

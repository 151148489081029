import { createContext } from 'react';
import { createConsumer } from '@rails/actioncable';

const ActionCableContext = createContext();

function ActionCableProvider({ children }) {
  const actioncable = createConsumer();
  return <ActionCableContext.Provider value={actioncable}>{children}</ActionCableContext.Provider>;
}

export { ActionCableContext, ActionCableProvider };

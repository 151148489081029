import React, { memo, useEffect } from 'react';
import { Position, useNodeId, useUpdateNodeInternals, useReactFlow } from 'reactflow';

import ConnectableHandle from '../Shared/ConnectableHandle';
import NameEditor from '../Shared/NameEditor';
import useNameEditor from '../Shared/useNameEditor';
import Button from '../Shared/Button';

import { BaseNodeWrapper, Header, CodeSnippet, NodeContent, PaddedGroup } from '../Shared/StyledComponents';

const CustomCodeNode = ({ data }) => {
  const nodeId = useNodeId();
  const updateNodeInternals = useUpdateNodeInternals();
  const { setNodes } = useReactFlow();
  const nameEditor = useNameEditor();

  useEffect(() => {
    // generate new blank ids
    const input = data.input || `input:${crypto.randomUUID()}`;
    const catchall = data.catchall || `catchall:${crypto.randomUUID()}`;

    // update node.data
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) node.data = { ...node.data, catchall, input };
        return node;
      }),
    );

    // refresh internal handles
    updateNodeInternals(nodeId);
  }, []);

  return (
    <BaseNodeWrapper className="node-custom_code">
      <Header data-drag-handle>
        <ConnectableHandle type="target" id={data.input} position={Position.Left} inHeader={true} />
        <NameEditor parent="customCode" placeholder="untitled" value={data.name} onChange={(newName) => nameEditor.updateName(newName)} />
        <ConnectableHandle
          type="source"
          id={data.catchall}
          position={Position.Right}
          limit={{ key: 'sourceHandle', id: data.catchall, limit: 1 }}
          inHeader={true}
        />
      </Header>
      <NodeContent>
        <PaddedGroup>
          <CodeSnippet data-open-modal="javascript">
            {`(async (input) => {\n${data.source || ''}\n})();`}
            <Button decorative={true} label="CODE" icon="code" />
          </CodeSnippet>
        </PaddedGroup>
      </NodeContent>
    </BaseNodeWrapper>
  );
};

export default memo(CustomCodeNode);

import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="toggle-section"
export default class extends Controller {
  static targets = ['section', 'toggle'];

  expanded = false;

  toggle(expand, event) {
    if (event) event.preventDefault();

    this.expanded = expand;
    this.toggleTarget.innerText = this.expanded ? 'Collapse' : 'Expand';
    this.sectionTarget.style.display = this.expanded ? null : 'none';
  }

  connect() {
    if (!(this.hasToggleTarget && this.hasSectionTarget)) return;
    this.toggle(false);
    this.toggleTarget.addEventListener('click', (e) => this.toggle(!this.expanded, e));
  }
}

import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="media-data-prompt"
export default class extends Controller {
  static values = { url: String };
  static targets = ['detail', 'bubbles'];

  create_image_bubble(url, aspect_ratio) {
    const bubble = document.createElement('div');
    this.bubblesTarget.appendChild(bubble);
    bubble.className = 'bubble';

    if (aspect_ratio) bubble.dataset.aspect_ratio = aspect_ratio;

    bubble.classList.add('image');
    bubble.style.backgroundImage = `url(${url})`;
  }

  create_bubble(content, schema) {
    const bubble = document.createElement('div');
    this.bubblesTarget.appendChild(bubble);
    bubble.className = 'bubble';

    if (schema) {
      const wrapper = document.createElement('div');
      bubble.appendChild(wrapper);
      wrapper.className = 'schema';

      ['key', 'format', 'type'].forEach((field) => {
        if (!schema[field]) return;

        const bull = document.createElement('div');
        wrapper.appendChild(bull);
        bull.innerHTML = '&bull;';

        const item = document.createElement('div');
        wrapper.appendChild(item);
        item.innerText = schema[field];
      });
    }

    const inner = document.createElement('div');
    bubble.appendChild(inner);

    if ((typeof content === 'string' && content.includes('```')) || typeof content === 'boolean' || typeof content === 'object') {
      inner.classList.add('mono');
    }

    if (Array.isArray(content)) {
      inner.innerText = `[ ${content.join(', ')} ]`;
    } else if (typeof content === 'boolean' || typeof content === 'object') {
      inner.innerText = JSON.stringify(content, null, 2);
    } else {
      inner.innerText = content;
    }
  }

  async connect() {
    if (this.element.dataset.initialized) return;
    this.element.dataset.initialized = true;

    if (!this.urlValue) return;
    const response = await fetch(this.urlValue, { cache: 'no-cache' });
    let data = response.ok ? await response.json() : {};
    // console.log(data);

    if (this.element.dataset.key === 'node_prompt_text_request') {
      this.create_bubble(data.input);
    } else if (this.element.dataset.key === 'node_prompt_text_response') {
      if (!('output' in data)) {
        console.log('moving data to data.output');
        data.output = { ...data };
      }

      if (!('schema' in data)) {
        console.log('backfilling schema from output');
        data.schema = Object.fromEntries(
          Object.keys(data)
            .filter((x) => x !== 'output')
            .map((key) => [key, {}]),
        );
      }

      Object.keys(data.output).forEach((key) => {
        this.create_bubble(data.output[key], { key, ...data.schema[key] });
      });
    }

    if (this.element.dataset.key === 'node_prompt_image_request') {
      if (data.aspect_ratio) {
        this.detailTarget.innerText = data.aspect_ratio;
        this.detailTarget.parentElement.style.display = null;
      }

      if (data.prompt) this.create_bubble(data.prompt);
      if (data.negative_prompt) this.create_bubble(data.negative_prompt, { key: 'Negative Prompt' });
    } else if (this.element.dataset.key === 'node_prompt_image_response') {
      if (typeof data === 'string') data = { public_url: data };
      this.create_image_bubble(data.public_url, data.metadata?.aspect_ratio);
    }
  }
}

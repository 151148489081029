import React from 'react';
import styled from 'styled-components';

import FilledX from '../../../../assets/images/icons/x-filled.svg';
import PreviewArrow from '../../../../assets/images/icons/node-preview-arrow.svg';

const CloseButton = styled.div`
  position: absolute;
  top: 8px;
  right: -20px;
  width: 12px;
  height: 12px;
  cursor: pointer;
`;

const Preview = styled.div`
  position: absolute;
  width: 250px;
  padding: 10px;
  border-radius: 2px;
  font-family: var(--font-monospace);
  box-shadow: var(--shadow-normal);
  z-index: var(--z-index-max);
  top: 0;
  left: calc(100% - 2px);
  background: center center no-repeat var(--color-navy-70);
  color: var(--color-grey-20);

  .preview-content {
    min-height: 20px;
    max-height: 200px;
    white-space: pre-wrap;
    overflow-y: auto;
    font-size: 11px;
    overflow-wrap: break-word;
  }

  .cursor {
    background: var(--color-navy-50);
    animation: blink-halfway 1s step-end infinite;
  }

  &.image-prompt {
    max-height: unset;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;

    &[data-aspect_ratio='16:9'] {
      height: 140px;
    }

    &[data-aspect_ratio='21:9'] {
      height: 107px;
    }

    &[data-aspect_ratio='2:3'] {
      width: 166px;
    }

    &[data-aspect_ratio='3:2'] {
      height: 166px;
    }

    &[data-aspect_ratio='4:5'] {
      width: 200px;
    }

    &[data-aspect_ratio='5:4'] {
      height: 200px;
    }

    &[data-aspect_ratio='9:16'] {
      width: 140px;
    }

    &[data-aspect_ratio='9:21'] {
      width: 107px;
    }

    .preview-content {
      border-radius: 2px;
      overflow: hidden;
    }

    .preview-image {
      border-radius: 2px;
      overflow: hidden;
      background: center center no-repeat transparent;
      background-size: cover;
      position: absolute;
      inset: 0;
    }

    .loading-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    .loader-ring {
      position: relative;
      width: 36px;
      height: 36px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: var(--color-navy-50);
      div {
        border-width: 3px;
      }
    }
  }
`;

const PreviewPopOut = ({ type, children, onClose, aspect_ratio }) => {
  return (
    <Preview className={`${type} preview text nodrag nowheel`} data-aspect_ratio={aspect_ratio}>
      <PreviewArrow style={{ position: 'absolute', top: 10, left: -5 }} />

      <CloseButton onClick={onClose}>
        <FilledX />
      </CloseButton>

      <div className="preview-content">{children}</div>
    </Preview>
  );
};

export default PreviewPopOut;

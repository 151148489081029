import React, { memo, useState, useEffect, useCallback } from 'react';
import { Position, useNodeId, useUpdateNodeInternals, useReactFlow } from 'reactflow';
import styled from 'styled-components';

import ConnectableHandle from '../Shared/ConnectableHandle';
import NameEditor from '../Shared/NameEditor';
import useNameEditor from '../Shared/useNameEditor';
import Deletable from '../Shared/Deleteable';
import Button from '../Shared/Button';

import { BaseNodeWrapper, Header, NodeContent, PaddedGroup } from '../Shared/StyledComponents';

const SplitRow = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Row = styled.div`
  border-top: 1px solid var(--color-grey-20);
  padding-top: 12px;
  margin-top: 12px;
`;

const Title = styled.div`
  font-size: 12px;
  font-family: var(--font-body);
  color: var(--color-black);
`;

const CollectNode = ({ data }) => {
  const nodeId = useNodeId();
  const updateNodeInternals = useUpdateNodeInternals();
  const nameEditor = useNameEditor();
  const { setEdges, setNodes } = useReactFlow();

  const [outputs, setOutputs] = useState(data.outputs || []);

  useEffect(() => {
    // generate initial values
    const input = data.input || `input:${crypto.randomUUID()}`;
    const output = data.output || `output:${crypto.randomUUID()}`;

    let outputs = data.outputs || [];
    while (outputs.length === 0) outputs.push({ id: `output:${crypto.randomUUID()}`, key: 'step1' });
    setOutputs(outputs);

    // update node.data
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) node.data = { ...node.data, input, output, outputs };
        return node;
      }),
    );

    // refresh internal handles
    updateNodeInternals(nodeId);
  }, []);

  const addOutput = useCallback(() => {
    // generate new output, add it
    const output = { id: `output:${crypto.randomUUID()}`, key: 'step' };
    setOutputs((rows) => [...rows, output]);

    // update node.data
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) node.data = { ...node.data, outputs: [...(node.data?.outputs || []), output] };
        return node;
      }),
    );

    // refresh internal handles
    updateNodeInternals(nodeId);
  }, [updateNodeInternals]);

  const removeOutput = useCallback(
    (id) => () => {
      // remove handle
      setOutputs((rows) => [...rows.filter((row) => row.id !== id)]);

      // remove handle from node.data
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === nodeId) node.data = { ...node.data, outputs: [...node.data.outputs].filter((row) => row.id !== id) };
          return node;
        }),
      );

      // remove any edges connected to output
      setEdges((eds) => eds.filter((e) => e.sourceHandle !== id));

      // refresh internal handles
      updateNodeInternals(nodeId);
    },
    [updateNodeInternals],
  );

  const updateOutput = useCallback(
    (id, field) => (event) => {
      // update handle in node.data
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === nodeId) {
            // select output
            const outputs = node.data.outputs;
            const i = outputs.findIndex((row) => row.id == id);

            // update node.data
            outputs[i][field] = event.target.value;
            node.data = { ...node.data, outputs };
          }
          return node;
        }),
      );

      // refresh internal handles
      updateNodeInternals(nodeId);
    },
    [updateNodeInternals],
  );

  return (
    <BaseNodeWrapper className="node-collect">
      <Header data-drag-handle>
        <ConnectableHandle type="target" id={data.input} position={Position.Left} inHeader={true} />
        <NameEditor parent="collect" placeholder="collect" value={data.name} onChange={(name) => nameEditor.updateName(name)} />
        <ConnectableHandle type="source" id={data.output} position={Position.Right} inHeader={true} />
      </Header>
      <NodeContent>
        <PaddedGroup>
          <Title>Collect each return value and return as one object.</Title>
          <p className="mt-12 split">
            <span>Steps</span>
            <Button extraStyle={{ backgroundColor: 'var(--color-mint-70)', color: 'var(--color-white)' }} label="ADD" onClick={addOutput} />
          </p>
        </PaddedGroup>

        {outputs.map((output) => (
          <Deletable xButtonLeft="-24px" xButtonTop="21px" rowClassName=" row" onDeleteClick={removeOutput(output.id)} key={output.id}>
            <Row>
              <PaddedGroup>
                <SplitRow>
                  <input
                    type="text"
                    style={{ flexGrow: 1 }}
                    className="input key"
                    placeholder="key"
                    defaultValue={output.key}
                    onChange={updateOutput(output.id, 'key')}
                  />

                  <ConnectableHandle
                    type="source"
                    id={output.id}
                    isDetour={true}
                    position={Position.Right}
                    limit={{ key: 'sourceHandle', id: output.id, limit: 1 }}
                    wrapperStyle={{ transform: 'none', position: 'static' }}
                  />
                </SplitRow>
              </PaddedGroup>
            </Row>
          </Deletable>
        ))}
      </NodeContent>
    </BaseNodeWrapper>
  );
};

export default memo(CollectNode);

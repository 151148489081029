import { memo, useState, useCallback, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { post as rails_post } from '@rails/request.js';

import { ActionCableContext } from '../Shared/ActionCable';
import Magic from '../../../../assets/images/icons/magic.svg';
import FilledX from '../../../../assets/images/icons/x-filled.svg';

const Banner = styled.div`
  position: absolute;
  top: 0px;
  left: 150px;
  display: flex;
  border-radius: 2px;
  background: var(--color-white);
  box-shadow: var(--shadow-normal);
  font-size: 11px;
  font-family: var(--font-monospace);
  font-weight: var(--weight-semibold);
  line-height: normal;

  &.with-nag {
    width: calc(100% - 150px - 16px - 16px);
    right: 0;
  }
`;

const Button = styled.div`
  padding: 11px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  color: var(--color-black);

  &.dimmed {
    --icon-color: var(--color-grey-50);
    color: var(--color-grey-50);
  }
`;

const Nag = styled.div`
  padding: 11px 16px;
  width: 100%;
  border-left: 1px solid var(--color-grey-20);
  color: var(--color-grey-60);
  display: flex;
  align-items: center;
  justify-content: space-between;

  .dismiss {
    cursor: pointer;
  }
`;

const Log = styled.div`
  position: absolute;
  top: 45px;
  left: 150px;
  width: 350px;
  max-height: 250px;
  overflow: auto;
  border-radius: 2px;
  background: var(--color-white);
  box-shadow: var(--shadow-normal);
  padding: 20px 16px;
  font-size: 11px;
  font-family: var(--font-monospace);
  font-weight: var(--weight-semibold);
  line-height: normal;
`;

const DismissLog = styled.div`
  position: absolute;
  top: 50px;
  left: 510px;
  cursor: pointer;
`;

const LogLine = styled.div`
  display: flex;
  margin: 0 0 25px 0;

  &:last-child {
    margin: 0;
  }

  svg {
    display: block;
    width: 20px;
    height: 20px;

    &.success {
      --icon-color: var(--color-green-50);
    }

    &.error {
      --icon-color: var(--color-red-50);
    }
  }

  div {
    margin-left: 10px;
    flex: 15px;
  }
`;

const MagicSetup = ({ blueprintId, openapiSpec, sampleTeamIsActive }) => {
  const [nag, setNag] = useState(!openapiSpec);
  const [pending, setPending] = useState(false);
  const [messages, setMessages] = useState([]);

  const actioncable = useContext(ActionCableContext);

  useEffect(() => {
    const channel = actioncable.subscriptions.create(
      { channel: 'BlueprintsChannel', id: blueprintId },
      {
        received: (data) => {
          if (data.command == 'update_auto_setup_message') {
            setMessages((x) => [data, ...x]);
            if (data.success || data.error) setPending(false);
          } else if (data.command == 'replace_graph') {
            setPending(false);
          }
        },
      },
    );
    return () => channel.unsubscribe();
  }, [blueprintId]);

  const onClick = useCallback(
    async (event) => {
      event.preventDefault();

      // ignore for sample team
      if (sampleTeamIsActive) {
        alert('Please sign up to see Magic Setup in action!');
        return;
      }

      // ignore double clicks
      if (pending) return;

      if (openapiSpec) {
        if (confirm('This will overwrite your current Session Manager with a new one based on your API docs.\n\nAre you sure?')) {
          setPending(true);
          await rails_post('./auto_setup_session_manager');
        }
      } else {
        window.location = './context';
      }
    },
    [pending, messages],
  );

  return (
    <>
      <Banner className={nag ? 'with-nag' : ''}>
        <Button onClick={onClick} className={!openapiSpec ? 'dimmed' : ''}>
          <Magic />
          Magic Setup
        </Button>
        {nag && (
          <Nag>
            Upload your API docs to enable automatic session manager setup.
            <FilledX className="dismiss" onClick={() => setNag(false)} />
          </Nag>
        )}
      </Banner>
      {messages.length > 0 && (
        <Log>
          {messages.map((log, i) => (
            <LogLine key={i}>
              <Magic className={`${log.error ? 'error' : ''} ${log.success ? 'success' : ''}`} />
              <div>{log.error || log.message}</div>
            </LogLine>
          ))}
        </Log>
      )}
      {messages.length > 0 && (
        <DismissLog>
          <FilledX onClick={() => setMessages([])} />
        </DismissLog>
      )}
    </>
  );
};

export default memo(MagicSetup);

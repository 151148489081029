import React, { memo, useEffect, useState, useCallback, useContext } from 'react';
import { Position, useNodeId, useUpdateNodeInternals, useReactFlow } from 'reactflow';
import { post as rails_post } from '@rails/request.js';

import { ActionCableContext } from '../Shared/ActionCable';
import ConnectableHandle from '../Shared/ConnectableHandle';
import NameEditor from '../Shared/NameEditor';
import useNameEditor from '../Shared/useNameEditor';
import Button from '../Shared/Button';
import PreviewPopOut from '../Shared/PreviewPopOut';

import { BaseNodeWrapper, Header, CodeSnippet, LabelAndField, NodeContent, PaddedGroup, Dropdown } from '../Shared/StyledComponents';

const DEFAULT_PROMPT = 'dogs playing poker on the moon';
const ASPECT_RATIOS = ['random', '1:1', '16:9', '21:9', '2:3', '3:2', '4:5', '5:4', '9:16', '9:21'];

const LoadingCirclesForPreview = () => {
  return (
    <div className="loader-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

const PromptImageNode = ({ data, playground_stream_url }) => {
  const nodeId = useNodeId();
  const updateNodeInternals = useUpdateNodeInternals();
  const nameEditor = useNameEditor();
  const { setNodes } = useReactFlow();
  const [preview, setPreview] = useState();
  const [previewStream, setPreviewStream] = useState(() => crypto.randomUUID());
  const actioncable = useContext(ActionCableContext);

  // subscribe playground preview channel
  useEffect(() => {
    const channel = actioncable.subscriptions.create(
      { channel: 'PlaygroundPreviewsChannel', stream: previewStream },
      { received: (data) => setPreview(data.value) },
    );
    return () => channel.unsubscribe();
  }, [previewStream]);

  useEffect(() => {
    // generate initial values
    const input = data.input || `input:${crypto.randomUUID()}`;
    const output = data.output || `output:${crypto.randomUUID()}`;
    const prompt = data.prompt || DEFAULT_PROMPT;
    const aspect_ratio = data.aspect_ratio || ASPECT_RATIOS[0];

    // update node.data
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) node.data = { ...node.data, input, output, prompt, aspect_ratio };
        return node;
      }),
    );

    // refresh internal handles
    updateNodeInternals(nodeId);
  }, [nodeId, setNodes, updateNodeInternals]);

  const updateField = useCallback(
    (field) => (event) => {
      // update handle in node.data
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === nodeId) node.data[field] = event.target.value;
          return node;
        }),
      );

      // refresh internal handles
      updateNodeInternals(nodeId);
    },
    [updateNodeInternals],
  );

  const generatePreview = useCallback(async () => {
    // pick a random ratio for preview, so the popout can know correct ratio
    let random = data.aspect_ratio === 'random';
    if (random) data.aspect_ratio = ASPECT_RATIOS[Math.floor(1 + (Math.random() * ASPECT_RATIOS.length - 1))];

    await rails_post(playground_stream_url, {
      body: {
        id: '_random', // actor id
        stream: previewStream,
        mode: 'image',
        prompt_text: data.prompt,
        aspect_ratio: data.aspect_ratio,
      },
    });

    data.aspect_ratio = 'random';
  }, [previewStream, data]);

  const closePreview = useCallback(() => {
    setPreview();
    setPreviewStream(crypto.randomUUID());
  }, [previewStream]);

  return (
    <BaseNodeWrapper className="node-prompt_image">
      <Header data-drag-handle>
        <ConnectableHandle type="target" id={data.input} position={Position.Left} inHeader={true} />

        <NameEditor
          parent="promptImage"
          placeholder="prompt image"
          value={data.name}
          onChange={(newName) => nameEditor.updateName(newName)}
        />

        <ConnectableHandle
          type="source"
          id={data.output}
          position={Position.Right}
          limit={{ key: 'sourceHandle', id: data.output, limit: 1 }}
          inHeader={true}
        />
      </Header>
      <NodeContent>
        <PaddedGroup>
          <LabelAndField className="lg">
            <div className="split">
              <span className="cell">Prompt</span>
              <span className="cell">
                <Button label="PREVIEW" onClick={generatePreview} />
              </span>
              {preview && (preview === 'LOADING' || preview.startsWith('https://')) && (
                <PreviewPopOut type="image-prompt" onClose={closePreview} aspect_ratio={data.aspect_ratio}>
                  {preview === 'LOADING' && (
                    <div className="loading-content">
                      <LoadingCirclesForPreview />
                    </div>
                  )}
                  {preview.startsWith('https://') && (
                    <div className="preview-image nodrag" style={{ backgroundImage: `url(${preview})` }}></div>
                  )}
                </PreviewPopOut>
              )}
            </div>

            <CodeSnippet className={` wrap ${data.prompt ? '' : 'placeholder'}`} data-open-modal="text" data-field="prompt">
              {data.prompt || DEFAULT_PROMPT}
              <Button decorative={true} label="TEXT" icon="text" />
            </CodeSnippet>
          </LabelAndField>
        </PaddedGroup>
        <PaddedGroup className="mt-16">
          <LabelAndField className="lg">
            <div>Aspect Ratio</div>

            <Dropdown className="full" defaultValue={data.aspect_ratio} onChange={updateField('aspect_ratio')}>
              {ASPECT_RATIOS.map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </Dropdown>
          </LabelAndField>
        </PaddedGroup>
      </NodeContent>
    </BaseNodeWrapper>
  );
};

export default memo(PromptImageNode);

import React, { useState, useRef } from 'react';
import styled from 'styled-components';

const InputField = styled.input`
  text-align: center;
  padding: 1px 0;
  font-size: 11px;
  font-weight: var(--weight-semibold);
  font-family: var(--font-monospace);
`;

const NameEditor = ({ placeholder, value, onChange }) => {
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef(null);

  const handleChange = (event) => {
    if (onChange) onChange(event.target.value);
  };

  const handleKeyDown = (event) => {
    // prevent selecting node on shift
    if (event.key === 'Shift') event.stopPropagation();

    // blur on enter or esc
    if (event.key === 'Enter' || event.key === 'Escape') inputRef.current.blur();
  };

  const handleDoubleClick = () => {
    setIsEditing(true);
    setTimeout(() => inputRef.current.focus(), 50);
  };

  return (
    <div onDoubleClick={handleDoubleClick}>
      <InputField
        ref={inputRef}
        type="text"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        onFocus={(e) => (isEditing ? e.target.select() : e.target.blur())}
        onBlur={() => setIsEditing(false)}
        placeholder={placeholder}
        defaultValue={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        readOnly={!isEditing}
      />
    </div>
  );
};

export default NameEditor;

import React, { memo } from 'react';
import { Position, Handle } from 'reactflow';

const InvalidNode = ({ data }) => {
  console.log(data);

  return (
    <span data-drag-handle>
      <Handle type="target" id={data.input} position={Position.Left} />
      <span className="badge" data-status="error">
        UNKNOWN NODE TYPE
      </span>
      {(data.outputs || []).map((output) => (
        <Handle type="source" id={output.id} position={Position.Right} key={output.id} />
      ))}
    </span>
  );
};

export default memo(InvalidNode);

import React, { memo, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const AffixedInputContainer = styled.div`
  position: relative;

  .input {
    width: 100%;
  }

  .decorator {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--color-grey-50);
    font-size: 12px;

    &[data-placement='prefix'] {
      left: 5px;
    }

    &[data-placement='suffix'] {
      right: 5px;
    }
  }
`;

const AffixedInput = ({ extraParentClass, decorator, placement, buffer, decoratorProps, ...inputProps }) => {
  const decoratorRef = useRef(null);
  const inputRef = useRef(null);

  const [decoratorWidth, setDecoratorWidth] = useState(0);
  useEffect(() => setDecoratorWidth(decoratorRef.current.offsetWidth), []);

  const style = {};
  buffer ||= 5;
  if (placement == 'prefix') style.paddingLeft = `${decoratorWidth + buffer}px`;
  if (placement == 'suffix') style.paddingRight = `${decoratorWidth + buffer}px`;

  return (
    <AffixedInputContainer className={extraParentClass}>
      <input ref={inputRef} {...(inputProps || {})} style={style} />
      <div
        ref={decoratorRef}
        className="decorator"
        data-placement={placement}
        {...(decoratorProps || {})}
        onClick={() => {
          inputRef.current.focus();
          inputRef.current.setSelectionRange(inputRef.current.value.length, inputRef.current.value.length);
        }}
      >
        {decorator}
      </div>
    </AffixedInputContainer>
  );
};

export default memo(AffixedInput);

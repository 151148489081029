import React, { memo, useState, useEffect, useCallback, useContext } from 'react';
import { Position, useNodeId, useUpdateNodeInternals, useReactFlow } from 'reactflow';

import ConnectableHandle from '../Shared/ConnectableHandle';
import NameEditor from '../Shared/NameEditor';
import useNameEditor from '../Shared/useNameEditor';
import AffixedInput from '../Shared/AffixedInput';
import Button from '../Shared/Button';

import {
  BaseNodeWrapper,
  Header,
  Dropdown,
  CodeSnippet,
  LabelAndField,
  NodeContent,
  TightRows,
  PaddedGroup,
  RowsWithDividers,
} from '../Shared/StyledComponents';

const VERBS = ['GET', 'POST', 'PATCH', 'PUT', 'DELETE'];

const ApiNode = ({ api_host, data }) => {
  const nodeId = useNodeId();
  const updateNodeInternals = useUpdateNodeInternals();
  const nameEditor = useNameEditor();
  const { setEdges, setNodes } = useReactFlow();

  const [outputs, setOutputs] = useState(data.outputs || []);
  const [auth, setAuth] = useState('auth' in data ? data.auth : 'bearer');
  const [verb, setVerb] = useState('verb' in data ? data.verb : VERBS[0]);
  const [fail_run, setFailRun] = useState('fail_run' in data ? data.fail_run : true);
  const [form_data, setFormData] = useState('form_data' in data ? data.form_data : false);
  // This isn't actually used in the data, but it helps us juggle the UI.

  // For a given verb, return the values available for its dropdown.
  // Based on this we'll set some more specific fields.
  // form_data will either be true or false, but payload is set no matter what,
  const formDataOptions = () => {
    // case switch around verb:
    switch (verb) {
      case 'GET':
        return ['Query Params'];
      case 'POST':
      case 'PATCH':
      case 'PUT':
        return ['JSON Payload', 'Form Data'];
      case 'DELETE':
        return [];
      default:
        return [];
    }
  };

  const [selectedFormDataOption, setSelectedFormDataOption] = useState(data.form_data ? 'Form Data' : 'Query Params');

  useEffect(() => {
    // If Form Body is set, we can set that bool, satisfying the original need.
    const isFormData = selectedFormDataOption === 'Form Data';
    setFormData(isFormData);
    nameEditor.updateField('form_data', isFormData);
  }, [selectedFormDataOption]);

  const handleFormDataOptionChange = (event) => {
    setSelectedFormDataOption(event.target.value);
  };

  useEffect(() => {
    // generate initial values
    const input = data.input || `input:${crypto.randomUUID()}`;
    const path = data.path || '/';
    const bearer_token = data.bearer_token || '{{SESSION_DATA.token}}';
    const basic_username = data.basic_username || '{{ACTOR.username}}';
    const basic_password = data.basic_password || '{{ACTOR.password}}';
    const payload = data.payload || '{\n  \n}';

    // generate outputs if needed
    let outputs = data.outputs || [];
    if (!outputs.find((x) => x.condition === 'pass')) outputs.push({ id: `output:${crypto.randomUUID()}`, condition: 'pass' });
    if (!outputs.find((x) => x.condition === 'fail')) outputs.push({ id: `output:${crypto.randomUUID()}`, condition: 'fail' });
    setOutputs(outputs);

    // update node.data
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) {
          node.data = {
            ...node.data,
            input,
            outputs,
            auth,
            verb,
            fail_run,
            form_data,
            path,
            bearer_token,
            basic_username,
            basic_password,
            payload,
          };
        }
        return node;
      }),
    );

    // refresh internal handles
    updateNodeInternals(nodeId);
  }, [updateNodeInternals]);

  const updateField = useCallback(
    (field, codemirror) => (event) => {
      if (codemirror && !event.docChanged) return;

      let value = event.target.value;
      if (codemirror) value = event.state.doc.toString();
      if (event.target.type === 'checkbox') value = event.target.checked;

      if (field === 'auth') setAuth(value);
      if (field === 'verb') setVerb(value);

      setSelectedFormDataOption(formDataOptions()[0]);

      // I don't know why but i have to make sure the value is a boolean,
      // not a string from the <select>
      if (field === 'fail_run') {
        setFailRun(value == 'true');
        value = value == 'true';
      }

      if (field === 'form_data') setFormData(value);

      // disconnect anything connected to fail
      if (field === 'fail_run' && value) {
        setEdges((eds) => eds.filter((e) => e.sourceHandle !== outputs.find((x) => x.condition === 'fail')?.id));
      }

      // update handle in node.data
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === nodeId) node.data[field] = value;
          return node;
        }),
      );

      // refresh internal handles
      updateNodeInternals(nodeId);
    },
    [updateNodeInternals],
  );

  return (
    <BaseNodeWrapper className="node-api">
      <Header data-drag-handle>
        <ConnectableHandle type="target" id={data.input} position={Position.Left} inHeader={true} />
        <NameEditor parent="api" placeholder="api" value={data.name} onChange={(newName) => nameEditor.updateName(newName)} />
        <ConnectableHandle
          type="source"
          id={outputs.find((x) => x.condition === 'pass')?.id}
          position={Position.Right}
          limit={{ key: 'sourceHandle', id: outputs.find((x) => x.condition === 'pass')?.id, limit: 1 }}
          inHeader={true}
        />
      </Header>
      <NodeContent>
        <RowsWithDividers>
          <PaddedGroup>
            <LabelAndField>
              <Dropdown className="transparent" data-auth-mode={auth} defaultValue={auth} onChange={updateField('auth')}>
                <option value="none">No authorization</option>
                <option value="bearer">Bearer authorization</option>
                <option value="basic">Basic authorization</option>
              </Dropdown>
            </LabelAndField>

            {auth === 'bearer' && (
              <input type="text" className="mt-12 input w-full" defaultValue={data.bearer_token} onChange={updateField('bearer_token')} />
            )}

            {auth === 'basic' && (
              <TightRows className="mt-12">
                <input
                  type="text"
                  placeholder="Username"
                  className="input w-full grow"
                  defaultValue={data.basic_username}
                  onChange={updateField('basic_username')}
                />

                <input
                  type="text"
                  placeholder="Password"
                  className="input w-full grow"
                  defaultValue={data.basic_password}
                  onChange={updateField('basic_password')}
                />
              </TightRows>
            )}
          </PaddedGroup>

          <PaddedGroup>
            <LabelAndField>
              <Dropdown className="transparent" defaultValue={data.verb} onChange={updateField('verb')}>
                {VERBS.map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </Dropdown>
            </LabelAndField>
            <div className="mt-12">
              <AffixedInput
                placement="prefix"
                decorator={'API_HOST'}
                decoratorProps={{ 'data-tooltip-id': 'node-tooltip', 'data-tooltip-content': api_host }}
                type="text"
                extraParentClass="w-full"
                className={'input'}
                placeholder="/"
                defaultValue={data.path}
                onChange={updateField('path')}
              />
            </div>
          </PaddedGroup>

          <PaddedGroup style={{ display: verb === 'DELETE' ? 'none' : '' }}>
            <LabelAndField>
              <div className="title">
                <Dropdown
                  className={`transparent  ${formDataOptions().length === 1 ? 'no-arrow' : ''}`}
                  defaultValue={selectedFormDataOption}
                  onChange={handleFormDataOptionChange}
                  disabled={formDataOptions().length === 1}
                >
                  {formDataOptions().map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </Dropdown>
              </div>

              <CodeSnippet data-open-modal="javascript" data-field="payload">
                {data.payload}
                <Button decorative={true} label="JSON" icon="json" />
              </CodeSnippet>
            </LabelAndField>
          </PaddedGroup>
          <PaddedGroup className="split">
            <div className="cell">
              <Dropdown className="transparent secondary" defaultValue={fail_run} onChange={updateField('fail_run')}>
                <option value={true}>Fail run on error</option>
                <option value={false}>Continue run on error</option>
              </Dropdown>
            </div>

            {fail_run && (
              <div
                className="cell"
                style={{
                  fontSize: '12px',
                }}
              >
                {/* Placeholder to avoid some jumpiness */}
                &nbsp;
              </div>
            )}

            {!fail_run && (
              <div
                className="cell"
                style={{
                  fontFamily: 'var(--font-body)',
                  color: 'var(--color-grey-100)',
                  position: 'relative',
                  fontSize: '12px',
                  display: 'flex',
                  gap: '0 12px',
                }}
              >
                <span>Error</span>
                <ConnectableHandle
                  type="source"
                  id={outputs.find((x) => x.condition === 'fail')?.id}
                  position={Position.Right}
                  limit={{ key: 'sourceHandle', id: outputs.find((x) => x.condition === 'fail')?.id, limit: 1 }}
                />
              </div>
            )}
          </PaddedGroup>
        </RowsWithDividers>
      </NodeContent>
    </BaseNodeWrapper>
  );
};

export default memo(ApiNode);

import '../assets/stylesheets/application.scss';

import Rails from '@rails/ujs';
import '@hotwired/turbo-rails';

import 'chartkick/chart.js';
import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import autocolors from 'chartjs-plugin-autocolors';

import { createAvatar } from '@dicebear/core';
import { shapes } from '@dicebear/collection';

import { Toast } from 'bootstrap';

import LocalTime from 'local-time';

import './channels';
import './controllers';
import './controllers/stimulus_components';
import './components/graph';
import './components/codemirror';

// live reload
if (window.location.host.includes('.test')) {
  new EventSource('http://127.0.0.1:8000/esbuild').addEventListener('change', () => location.reload());
}

const debounce = (callback, wait) => {
  let timeout;
  return (...args) => {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => callback.apply(context, args), wait);
  };
};

Rails.start();

Chart.register(annotationPlugin);
Chart.register(autocolors);

LocalTime.start();

const init_avatar = (el) => {
  const avatar = createAvatar(shapes, { seed: el.dataset.actorAvatar });
  el.innerHTML = avatar.toString();
};

const init_external_submit = (el) => {
  el.addEventListener('click', (e) => {
    e.preventDefault();
    const form_id = el.dataset.externalSubmit;
    const form = document.querySelector(form_id);
    if (!form) console.warn(`external form "${form_id} not found`);

    let original = el.innerText;
    if (el.dataset.disableWith) el.innerText = el.dataset.disableWith;

    el.classList.add('disabled');

    // use ujs to submit remote forms
    if (form.dataset.remote) {
      if (!form.dataset.attached_ajax_events) {
        form.addEventListener('ajax:complete', () => {
          el.classList.remove('disabled');
          el.innerText = original;
        });

        form.addEventListener('ajax:error', (e) => {
          const [data] = e.detail;
          console.warn(data);
          alert(`Error saving: ${[data.errors].flat().join('\n\n')}`);
        });

        form.dataset.attached_ajax_events = true;
      }

      Rails.fire(form, 'submit');
    } else {
      form.requestSubmit();
    }
  });
};

const init_auto_submit = (el) => {
  el.addEventListener(
    'input',
    debounce(() => {
      if (el.dataset.remote) {
        if (!el.dataset.attached_ajax_events) {
          el.addEventListener('ajax:error', (e) => {
            const [data] = e.detail;
            console.warn(data);
            alert(`Error saving: ${[data.errors].flat().join('\n\n')}`);
          });

          el.dataset.attached_ajax_events = true;
        }

        Rails.fire(el, 'submit');
      } else {
        el.requestSubmit();
      }
    }, 250),
  );
};

const init_field_toggle = (el) => {
  el.dataset.initial = el.classList.contains('on');

  el.addEventListener('ajax:success', () => {
    el.classList.toggle('on');
    el.dataset.initial = el.classList.contains('on');
  });

  el.addEventListener('ajax:error', () => {
    if (el.dataset.initial == true) {
      el.classList.add('on');
    } else {
      el.classList.remove('on');
    }
  });
};

document.addEventListener('turbo:load', () => {
  document.querySelectorAll('[data-actor-avatar]').forEach((el) => {
    if (el.dataset.initialized) return;
    el.dataset.initialized = true;
    init_avatar(el);
  });

  document.querySelectorAll('[data-auto-submit]').forEach((el) => {
    if (el.dataset.initialized) return;
    el.dataset.initialized = true;
    init_auto_submit(el);
  });

  document.querySelectorAll('[data-toggle-mobile-nav]').forEach((el) => {
    if (el.dataset.initialized) return;
    el.dataset.initialized = true;

    const parent = document.querySelector('.grid');
    const glass = document.querySelector('.nav-glass');

    el.addEventListener('click', (e) => {
      e.preventDefault();
      const visible = parent.dataset.nav == 'visible';
      parent.dataset.nav = visible ? null : 'visible';
      if (glass) glass.classList.toggle('visible');
    });
  });

  document.querySelectorAll('.flash.toast').forEach((el) => {
    new Toast(el).show();
    el.addEventListener('hidden.bs.toast', () => setTimeout(() => el.remove(), 250));
  });

  // these don't work with an idempotent check...
  document.querySelectorAll('[data-external-submit]').forEach((el) => init_external_submit(el));
  document.querySelectorAll('[data-field-toggle]').forEach((el) => init_field_toggle(el));
});

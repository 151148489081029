import React, { useMemo, useContext } from 'react';
import { Position, getConnectedEdges, Handle, useNodeId, useStore } from 'reactflow';
import styled from 'styled-components';

import { ConnectedHandleIdsContext } from '../index';

const selector = (s) => ({ nodeInternals: s.nodeInternals, edges: s.edges });

const HandleWrapper = styled.div`
  width: calc(var(--handle-size) * 2);
  height: calc(var(--handle-size) * 2);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 2px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${(props) => props.$position === Position.Left && `left: var(--h-padding);`}
  ${(props) => props.$position === Position.Right && `right: var(--h-padding);`}

  &,
  & * {
    transition: all 0.2s linear;
    transition-property: border-color, background;
  }

  border: 1px solid transparent;
  ${(props) => props.$isDetour && `border-color: var(--color-grey-30);`}
  ${(props) => props.$isDetour && props.$isConnected && `border-color: var(--node-title-fill-color);`}

  .react-flow__handle {
    position: static;
    transform: none;
    width: var(--handle-size);
    height: var(--handle-size);
    min-width: var(--handle-size);
    min-height: var(--handle-size);
    border-radius: var(--handle-size);

    // customize handle color by state
    background: var(--color-grey-40);
    ${(props) => props.$isConnected && `background: var(--color-grey-90);`}
    ${(props) => props.$inHeader && !props.$isConnected && `background: var(--node-handle-inactive);`}
    ${(props) => props.$inHeader && props.$isConnected && `background: var(--node-handle-active);`}
    ${(props) => props.$isDetour && props.$isConnected && `background: var(--node-title-fill-color);`}
  }
`;

const ConnectableHandle = ({ wrapperStyle = {}, inHeader = false, isDetour = false, ...props }) => {
  const { nodeInternals, edges } = useStore(selector);
  const nodeId = useNodeId();
  const connectedHandleIdsContext = useContext(ConnectedHandleIdsContext);
  const isConnected = useMemo(() => connectedHandleIdsContext[props.id], [connectedHandleIdsContext, props.id]);

  const connectable = useMemo(() => {
    const node = nodeInternals.get(nodeId);
    const connectedEdges = getConnectedEdges([node], edges);

    if (typeof props.limit === 'function') {
      return props.limit({ node, connectedEdges });
    }

    if (typeof props.limit === 'number') {
      return connectedEdges.length < props.limit;
    }

    if (typeof props.limit === 'object') {
      return connectedEdges.filter((e) => e[props.limit.key] === props.limit.id).length < props.limit.limit;
    }

    return props.limit;
  }, [nodeInternals, edges, nodeId, props.limit]);

  return (
    <HandleWrapper style={wrapperStyle} $position={props.position} $inHeader={inHeader} $isDetour={isDetour} $isConnected={isConnected}>
      <Handle {...props} isConnectable={connectable} />
    </HandleWrapper>
  );
};

export default ConnectableHandle;

import React, { memo, useEffect, useState, useCallback, useContext } from 'react';
import { Position, useNodeId, useUpdateNodeInternals, useReactFlow } from 'reactflow';
import { post as rails_post } from '@rails/request.js';

import { ActionCableContext } from '../Shared/ActionCable';
import ConnectableHandle from '../Shared/ConnectableHandle';
import NameEditor from '../Shared/NameEditor';
import useNameEditor from '../Shared/useNameEditor';
import Button from '../Shared/Button';
import ToggleArrow from '../Shared/ToggleArrow';
import Deletable from '../Shared/Deleteable';
import PreviewPopOut from '../Shared/PreviewPopOut';
import DropdownWithOther from '../Shared/DropdownWithOther';

import {
  BaseNodeWrapper,
  Header,
  CodeSnippet,
  LabelAndField,
  NodeContent,
  TightRows,
  PaddedGroup,
  RowsWithDividers,
  TwoThirdsRow,
} from '../Shared/StyledComponents';

const DEFAULT_PROMPT = 'write a bio for your profile';
const TYPES = ['String', 'Number', 'Boolean'];

const PromptTextNode = ({ data, playground_stream_url }) => {
  const nodeId = useNodeId();
  const updateNodeInternals = useUpdateNodeInternals();
  const { setNodes } = useReactFlow();
  const [preview, setPreview] = useState([]);
  const [previewStream, setPreviewStream] = useState(() => crypto.randomUUID());

  const actioncable = useContext(ActionCableContext);
  const nameEditor = useNameEditor();

  const setIsStructureVisible = (value) => nameEditor.updateField('_drawer_visible', value);

  // subscribe playground preview channel
  useEffect(() => {
    const channel = actioncable.subscriptions.create(
      { channel: 'PlaygroundPreviewsChannel', stream: previewStream },
      {
        received: (data) => {
          if (data.action === 'update') {
            setPreview([[0, data.value]]);
          } else {
            setPreview((x) => [...x, [data.order, data.value]]);
          }
        },
      },
    );
    return () => channel.unsubscribe();
  }, [previewStream]);

  useEffect(() => {
    // generate initial values
    const input = data.input || `input:${crypto.randomUUID()}`;
    const output = data.output || `output:${crypto.randomUUID()}`;
    const prompt = data.prompt || DEFAULT_PROMPT;
    const schema = data.schema || [{ key: 'response', format: '1-2 sentences', type: 'String' }];

    // update node.data
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) {
          // convert limit to schema format, assume first field is response
          if (node.data.limit) {
            schema[0].format = node.data.limit;
            delete node.data.limit;
          }

          node.data = { ...node.data, input, output, prompt, schema };
        }
        return node;
      }),
    );

    // refresh internal handles
    updateNodeInternals(nodeId);
  }, [nodeId, setNodes, updateNodeInternals]);

  const addField = useCallback(() => {
    setIsStructureVisible(true);
    // update node.data
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) {
          node.data = {
            ...node.data,
            schema: [
              ...node.data.schema,
              { key: `field_${Object.keys(node.data.schema).length + 1}`, format: '1-2 sentences', type: 'String' },
            ],
          };
        }
        return node;
      }),
    );

    // refresh internal handles
    updateNodeInternals(nodeId);
  }, [updateNodeInternals]);

  const removeField = useCallback(
    (key) => () => {
      // remove field from node.data.schema
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === nodeId) node.data = { ...node.data, schema: [...node.data.schema].filter((field) => field.key !== key) };
          return node;
        }),
      );

      // refresh internal handles
      updateNodeInternals(nodeId);
    },
    [updateNodeInternals],
  );

  // Modify the field at the given index and field with
  // the given value, which can be an event or a direct value.
  // Most of the other nodes us a similar function but only base it off the event target's value,
  // this one is a bit more complex.
  const updateField = useCallback(
    (index, field) => (eventOrValue) => {
      // Determine if the argument is an event or a direct value
      const value = eventOrValue && eventOrValue.target ? eventOrValue.target.value : eventOrValue;

      // Update node data
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === nodeId) {
            // update node.data
            const schema = node.data.schema;
            schema[index][field] = value;
            node.data = { ...node.data, schema };
          }

          return node;
        }),
      );

      // Refresh internal handles
      updateNodeInternals(nodeId);
    },
    [updateNodeInternals],
  );

  const generatePreview = useCallback(async () => {
    await rails_post(playground_stream_url, {
      body: {
        id: '_random', // actor id
        stream: previewStream,
        prompt_text: data.prompt,
        schema: Object.fromEntries(data.schema.map((x) => [x.key, { format: x.format, type: x.type }])),
      },
    });
  }, [previewStream, data]);

  const closePreview = useCallback(() => {
    setPreview([]);
    setPreviewStream(crypto.randomUUID());
  }, [previewStream]);

  const structureRecords = data.schema || [];

  return (
    <BaseNodeWrapper className="node-prompt_text">
      <Header data-drag-handle>
        <ConnectableHandle type="target" id={data.input} position={Position.Left} inHeader={true} />
        <NameEditor
          parent="promptText"
          placeholder="prompt text"
          value={data.name}
          onChange={(newName) => nameEditor.updateName(newName)}
        />
        <ConnectableHandle
          type="source"
          id={data.output}
          position={Position.Right}
          limit={{ key: 'sourceHandle', id: data.output, limit: 1 }}
          inHeader={true}
        />
      </Header>
      <NodeContent>
        <PaddedGroup>
          <LabelAndField className="lg">
            <div className="split">
              <span className="cell">Prompt</span>
              <span className="cell">
                <Button label="PREVIEW" onClick={generatePreview} />
              </span>
              {preview.length > 0 && (
                <PreviewPopOut type="text-prompt" onClose={closePreview}>
                  {preview
                    .sort((a, b) => a[0] - b[0])
                    .map((x) => x[1])
                    .join('')}
                  <span className="cursor">&nbsp;</span>
                </PreviewPopOut>
              )}
            </div>
            <div className="fields">
              <CodeSnippet className={` wrap ${data.prompt ? '' : 'placeholder'}`} data-open-modal="text" data-field="prompt">
                {data.prompt || DEFAULT_PROMPT}
                <Button decorative={true} label="TEXT" icon="text" />
              </CodeSnippet>
            </div>
          </LabelAndField>
        </PaddedGroup>
        <LabelAndField className="lg mt-16">
          <PaddedGroup>
            <div className="split" style={{ marginBottom: 0 }}>
              <span className="cell">
                <ToggleArrow
                  label={`JSON Structure (${structureRecords.length})`}
                  active={data._drawer_visible}
                  onClick={() => setIsStructureVisible(!data._drawer_visible)}
                />
              </span>

              <span className="cell">
                <Button label="ADD" onClick={addField} />
              </span>
            </div>
          </PaddedGroup>

          {structureRecords.length > 0 && (
            <RowsWithDividers style={{ display: data._drawer_visible ? 'block' : 'none' }}>
              {structureRecords.map((field, index) => (
                <Deletable
                  onDeleteClick={removeField(field.key)}
                  key={`schema-${index}`}
                  xButtonLeft="-24px"
                  xButtonTop={index === 0 ? '9px' : '25px'}
                >
                  <PaddedGroup>
                    <TightRows>
                      <TwoThirdsRow>
                        <label>Key</label>
                        <input
                          type="text"
                          className="input"
                          placeholder="response"
                          defaultValue={field.key}
                          onChange={updateField(index, 'key')}
                        />
                      </TwoThirdsRow>
                      <TwoThirdsRow>
                        <label>Format</label>
                        <input
                          type="text"
                          className="input"
                          placeholder="none"
                          defaultValue={field.format}
                          onChange={updateField(index, 'format')}
                        />
                      </TwoThirdsRow>
                      <TwoThirdsRow>
                        <label>Type</label>
                        <DropdownWithOther options={TYPES} currentValue={field.type || TYPES[0]} onChange={updateField(index, 'type')} />
                      </TwoThirdsRow>
                    </TightRows>
                  </PaddedGroup>
                </Deletable>
              ))}
            </RowsWithDividers>
          )}
        </LabelAndField>
      </NodeContent>
    </BaseNodeWrapper>
  );
};

export default memo(PromptTextNode);

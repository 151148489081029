import React, { memo, useState, useEffect, useCallback } from 'react';
import { Position, useNodeId, useUpdateNodeInternals, useReactFlow } from 'reactflow';
import styled from 'styled-components';

import ConnectableHandle from '../Shared/ConnectableHandle';
import NameEditor from '../Shared/NameEditor';
import useNameEditor from '../Shared/useNameEditor';

import { BaseNodeWrapper, Header, NodeContent, PaddedGroup, LabelAndField } from '../Shared/StyledComponents';

const SessionCols = styled.div`
  display: flex;
  overflow: hidden;
  & > div {
    padding-top: 12px;
    padding-bottom: 40px;
  }
  .left {
    flex: 1 1 0%;
    min-width: 0;

    display: flex;
    border-right: 1px solid var(--color-grey-20);
    padding-right: 12px;
  }

  .right {
    width: 180px;
    flex: 0 0 180px;
    display: flex;
    padding-left: 12px;
  }
`;

const Footer = styled.div`
  font-size: 11px;
  font-style: normal;
  font-weight: var(--weight-medium);
  font-family: var(--font-mono);
  color: var(--color-black);
  padding: 12px 0;
  border-top: 1px solid var(--color-grey-20);
  text-align: right;
  span {
    padding-right: 24px;
  }
`;

const SessionNode = ({ data }) => {
  const nodeId = useNodeId();
  const updateNodeInternals = useUpdateNodeInternals();
  const { setNodes } = useReactFlow();
  const nameEditor = useNameEditor();

  const [outputs, setOutputs] = useState(data.outputs || []);

  useEffect(() => {
    // generate new blank id
    const input = data.input || `input:${crypto.randomUUID()}`;

    // generate outputs if needed
    let outputs = data.outputs || [];
    if (!outputs.find((x) => x.condition === 'pass')) outputs.push({ id: `output:${crypto.randomUUID()}`, condition: 'pass' });
    if (!outputs.find((x) => x.condition === 'fail')) outputs.push({ id: `output:${crypto.randomUUID()}`, condition: 'fail' });
    setOutputs(outputs);

    // update node.data
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) node.data = { ...node.data, input, outputs };
        return node;
      }),
    );

    // refresh internal handles
    updateNodeInternals(nodeId);
  }, [outputs]);

  const updateOutput = useCallback(
    (field) => (event) => {
      // update handle in node.data
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === nodeId) node.data[field] = event.target.value;
          return node;
        }),
      );

      // refresh internal handles
      updateNodeInternals(nodeId);
    },
    [updateNodeInternals],
  );

  return (
    <BaseNodeWrapper className="node-session">
      <Header data-drag-handle>
        <ConnectableHandle type="target" id={data.input} position={Position.Left} inHeader={true} />
        <NameEditor parent="session" placeholder="session" value={data.name} onChange={(newName) => nameEditor.updateName(newName)} />
        <ConnectableHandle
          type="source"
          id={outputs.find((x) => x.condition === 'pass')?.id}
          position={Position.Right}
          inHeader={true}
          limit={{ key: 'sourceHandle', id: outputs.find((x) => x.condition === 'pass')?.id, limit: 1 }}
        />
      </Header>
      <NodeContent style={{ paddingTop: 0, paddingBottom: 0 }}>
        <PaddedGroup>
          <SessionCols>
            <LabelAndField className="left">
              <div>Lifetime</div>
              <input
                type="text"
                className="input w-full"
                placeholder="never"
                defaultValue={data.memory_ttl}
                onChange={updateOutput('memory_ttl')}
              />
            </LabelAndField>

            <LabelAndField className="right">
              <div>Key</div>

              <input
                type="text"
                className="input w-full"
                placeholder="name"
                defaultValue={data.user_key}
                onChange={updateOutput('user_key')}
              />
            </LabelAndField>
          </SessionCols>
        </PaddedGroup>
        <Footer>
          <PaddedGroup>
            <span>SESSION_DATA value missing</span>
            <ConnectableHandle
              type="source"
              id={outputs.find((x) => x.condition === 'fail')?.id}
              position={Position.Right}
              isDetour={true}
              limit={{ key: 'sourceHandle', id: outputs.find((x) => x.condition === 'fail')?.id, limit: 1 }}
            />
          </PaddedGroup>
        </Footer>
      </NodeContent>
    </BaseNodeWrapper>
  );
};

export default memo(SessionNode);

import { Controller } from '@hotwired/stimulus';
import consumer from '../channels/consumer';

// Connects to data-controller="playground-preview"
export default class extends Controller {
  static targets = ['output'];
  static values = { stream: String };

  messages = [];

  connect() {
    this.channel = consumer.subscriptions.create(
      { channel: 'PlaygroundPreviewsChannel', stream: this.streamValue },
      { received: this.received.bind(this) },
    );

    this.cursor = document.createElement('span');
    this.cursor.className = 'cursor';
    this.cursor.innerHTML = '&nbsp;';
    this.outputTarget.append(this.cursor);
  }

  disconnect() {
    if (this.channel) this.channel.unsubscribe();
  }

  received(data) {
    if (data.action === 'update') {
      this.messages = [[0, data.value]];
    } else {
      this.messages.push([data.order, data.value]);
    }

    this.render();
  }

  render() {
    this.outputTarget.innerHTML = this.messages
      .sort((a, b) => a[0] - b[0])
      .map((x) => x[1])
      .join('');

    this.outputTarget.append(this.cursor);
  }
}

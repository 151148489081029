import { Controller } from '@hotwired/stimulus';

// inspo: https://github.com/hotwired/turbo/issues/934 and https://github.com/kirillplatonov/dirty-form

// Connects to data-controller="dirty-form"
export default class extends Controller {
  verbose = false;

  inputs = {};

  log = (...args) => (this.verbose ? console.log.apply(console, args) : null);
  warn = (...args) => (this.verbose ? console.warn.apply(console, args) : null);

  confirm_leave = (event) => {
    if (this.is_dirty === undefined) return;

    this.warn(`[DirtyForm] confirm_leave showing warning`, this.is_dirty);
    // return;

    if (!window.confirm('You have unsaved changes that you will lose. Are you sure you want to leave?')) {
      event.preventDefault();
      if (event.type == 'beforeunload') event.returnValue = '';
    }
  };

  reset_state = () => {
    this.log(`[DirtyForm] reset_state`);

    // reset is_dirty state
    this.is_dirty = false;

    // reset initial values
    this.subscribe_inputs();
  };

  subscribe_inputs = () => {
    const inputs = [...this.element.elements].filter((x) => x.dataset.trackDirty);

    inputs.forEach((input) => {
      if (!input.name) {
        this.warn(`[DirtyForm] subscribe_inputs skipping unnamed input`, input);
        return;
      }

      let initial = input.value;
      if (input.type === 'checkbox') initial = input.checked;

      this.inputs[input.name] = { input, initial };

      input.addEventListener('change', this.compare_value.bind(this));
      input.addEventListener('input', this.compare_value.bind(this));
    });

    this.log(`[DirtyForm] subscribe_inputs`, this.inputs);
  };

  compare_value = (event) => {
    let input = event.target;
    this.inputs[input.name].is_dirty = this.inputs[input.name].initial !== input.value;
    this.log(`[DirtyForm] compare_value ${input.name} is_dirty = ${this.inputs[input.name].is_dirty}`);
    // this.log({ initial: this.inputs[input.name].initial, current: input.value });
  };

  get is_dirty() {
    return Object.values(this.inputs).find((x) => x.is_dirty === true);
  }

  set is_dirty(value) {
    this.log(`[DirtyForm] setting is_dirty = ${value}`);
    Object.keys(this.inputs).forEach((key) => (this.inputs[key].is_dirty = value));
  }

  connect() {
    this.log(`[DirtyForm] connect`, this.element);

    this.element.dirty_form_controller = this;

    window.addEventListener('beforeunload', this.confirm_leave);
    document.addEventListener('turbo:before-visit', this.confirm_leave);

    window.addEventListener('submit', this.reset_state);
    this.element.addEventListener('submit', this.reset_state);
    this.element.addEventListener('ajax:before', this.reset_state);

    this.subscribe_inputs();
  }

  disconnect() {
    this.log(`[DirtyForm] disconnect`, this.element);

    window.removeEventListener('beforeunload', this.confirm_leave);
    document.removeEventListener('turbo:before-visit', this.confirm_leave);

    window.removeEventListener('submit', this.reset_state);
    this.element.removeEventListener('submit', this.reset_state);
    this.element.removeEventListener('ajax:before', this.reset_state);
  }
}

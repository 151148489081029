import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="toggle-grouping"
export default class extends Controller {
  collapsed = {};

  toggle(event) {
    event.preventDefault();

    // transfer initial collapsed param to map
    if (!(event.params.grouping in this.collapsed)) this.collapsed[event.params.grouping] = event.params.collapsed;

    // toggle collapsed state
    const collapsed = !this.collapsed[event.params.grouping];
    this.collapsed[event.params.grouping] = collapsed;

    // update toggle ui
    collapsed ? event.currentTarget.classList.add('collapsed') : event.currentTarget.classList.remove('collapsed');

    // toggle groupings
    document
      .querySelectorAll(`[data-grouping='${event.params.grouping}']`)
      .forEach((x) => (x.style.display = collapsed ? 'none' : 'block'));
  }
}

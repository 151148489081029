import React, { memo } from 'react';
import { Position } from 'reactflow';
import styled from 'styled-components';

import ConnectableHandle from '../Shared/ConnectableHandle';

import { BaseNodeWrapper } from '../Shared/StyledComponents';

import Logo from '../../../../assets/images/logo.svg';

const Title = styled.h2`
  font-weight: var(--weight-semibold);
  color: var(--color-grey-100);
  display: flex;
  gap: 10px;

  .name {
    width: 100px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const Wrapper = styled(BaseNodeWrapper)`
  padding: 20px 24px;
  width: var(--node-width-sm);
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const LogoWrapper = styled(BaseNodeWrapper)`
  width: 64px;
  height: 64px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const EntryNode = (props) => {
  return (
    <>
      {props.graph == 'flow' && (
        <Wrapper data-drag-handle>
          <ConnectableHandle
            wrapperStyle={{ transform: 'none', top: '10px', right: '10px' }}
            type="source"
            id="entry"
            position={Position.Right}
            limit={1}
          />

          <Title>
            <div>{props.icon}</div>
            <div className="name">{props.name}</div>
          </Title>
        </Wrapper>
      )}

      {props.graph == 'session_manager' && (
        <LogoWrapper data-drag-handle>
          <ConnectableHandle
            wrapperStyle={{ transform: 'none', top: '10px', right: '10px' }}
            type="source"
            id="entry"
            position={Position.Right}
            limit={1}
          />
          <Logo style={{ width: '20px', height: '20px' }} />
        </LogoWrapper>
      )}
    </>
  );
};

export default memo(EntryNode);

import React, { memo, useState, useEffect, useCallback } from 'react';
import { Position, useNodeId, useUpdateNodeInternals, useReactFlow } from 'reactflow';
import styled from 'styled-components';

import ConnectableHandle from '../Shared/ConnectableHandle';
import NameEditor from '../Shared/NameEditor';
import useNameEditor from '../Shared/useNameEditor';
import AddButton from '../Shared/AddButton';
import Deletable from '../Shared/Deleteable';

import { BaseNodeWrapper, Header, Dropdown, NodeContent, TightRows, PaddedGroup, RowsWithDividers } from '../Shared/StyledComponents';

import { PREDICATES } from '../../../utils';

const build_condition = () => ({ left: '{{input}}', predicate: 'eq', right: '' });

const GroupHeader = styled.div`
  position: relative;
  font-size: 11px;
  font-weight: var(--weight-regular);
  font-family: var(--font-body);
  margin-bottom: 12px;
  color: var(--color-grey-100);
  text-align: right;
  padding-right: 18px;
`;

const PredicateFields = styled.div`
  display: flex;
  justify-content: stretch;
  align-items: center;
  select {
    width: 18%;
  }
  input {
    flex-grow: 1;
  }
`;

const ConditionalNode = ({ data }) => {
  const nodeId = useNodeId();
  const updateNodeInternals = useUpdateNodeInternals();
  const nameEditor = useNameEditor();
  const { setEdges, setNodes } = useReactFlow();

  const [outputs, setOutputs] = useState(data.outputs || []);

  useEffect(() => {
    // generate initial values
    const input = data.input || `input:${crypto.randomUUID()}`;
    const catchall = data.catchall || `catchall:${crypto.randomUUID()}`;

    let outputs = data.outputs || [];
    while (outputs.length === 0) outputs.push({ id: `output:${crypto.randomUUID()}`, condition: build_condition() });
    setOutputs(outputs);

    // update node.data
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) node.data = { ...node.data, input, catchall, outputs };
        return node;
      }),
    );

    // refresh internal handles
    updateNodeInternals(nodeId);
  }, []);

  const addOutput = useCallback(() => {
    // generate new output, add it
    const output = { id: `output:${crypto.randomUUID()}`, condition: build_condition() };
    setOutputs((rows) => [...rows, output]);

    // update node.data
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) node.data = { ...node.data, outputs: [...(node.data?.outputs || []), output] };
        return node;
      }),
    );

    // refresh internal handles
    updateNodeInternals(nodeId);
  }, [updateNodeInternals]);

  const removeOutput = useCallback(
    (id) => () => {
      // remove handle
      setOutputs((rows) => [...rows.filter((row) => row.id !== id)]);

      // remove handle from node.data
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === nodeId) node.data = { ...node.data, outputs: [...node.data.outputs].filter((row) => row.id !== id) };
          return node;
        }),
      );

      // remove any edges connected to output
      setEdges((eds) => eds.filter((e) => e.sourceHandle !== id));

      // refresh internal handles
      updateNodeInternals(nodeId);
    },
    [updateNodeInternals],
  );

  const updateOutput = useCallback(
    (id, field) => (event) => {
      // update handle in node.data
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === nodeId) {
            // select output
            const outputs = node.data.outputs;
            const i = outputs.findIndex((row) => row.id == id);

            // update node.data
            outputs[i].condition[field] = event.target.value;
            node.data = { ...node.data, outputs };
          }
          return node;
        }),
      );

      // refresh internal handles
      updateNodeInternals(nodeId);
    },
    [updateNodeInternals],
  );

  return (
    <BaseNodeWrapper className="node-conditional">
      <Header data-drag-handle>
        <ConnectableHandle type="target" id={data.input} position={Position.Left} inHeader={true} />
        <NameEditor parent="conditional" placeholder="if/else" value={data.name} onChange={(newName) => nameEditor.updateName(newName)} />
        <AddButton onClick={addOutput} inHeader={true} />
      </Header>
      <NodeContent>
        <RowsWithDividers>
          {outputs.map((output, index) => (
            <Deletable xButtonLeft="-24px" xButtonTop="2px" rowClassName=" row" onDeleteClick={removeOutput(output.id)} key={output.id}>
              <PaddedGroup>
                <GroupHeader>
                  <span>{index === 0 ? 'if' : 'else if'}</span>
                  <ConnectableHandle
                    type="source"
                    id={output.id}
                    position={Position.Right}
                    limit={{ key: 'sourceHandle', id: output.id, limit: 1 }}
                    wrapperStyle={{ right: '-2px' }}
                  />
                </GroupHeader>
                <TightRows>
                  <div>
                    <input
                      type="text"
                      className="input full"
                      placeholder="{{input}}"
                      defaultValue={output.condition.left}
                      onChange={updateOutput(output.id, 'left')}
                    />
                  </div>
                  <PredicateFields>
                    <Dropdown className="transparent" value={output.condition.predicate} onChange={updateOutput(output.id, 'predicate')}>
                      {Object.entries(PREDICATES).map(([key, value]) => (
                        <option key={key} value={key}>
                          {value}
                        </option>
                      ))}
                    </Dropdown>

                    <input
                      type="text"
                      className="input"
                      placeholder="value"
                      defaultValue={output.condition.right}
                      onChange={updateOutput(output.id, 'right')}
                    />
                  </PredicateFields>
                </TightRows>
              </PaddedGroup>
            </Deletable>
          ))}

          <PaddedGroup>
            <GroupHeader style={{ marginBottom: 0 }}>
              <span>else</span>
              <ConnectableHandle
                type="source"
                id={data.catchall}
                position={Position.Right}
                limit={{ key: 'sourceHandle', id: data.catchall, limit: 1 }}
                wrapperStyle={{
                  right: '-2px',
                }}
              />
            </GroupHeader>
          </PaddedGroup>
        </RowsWithDividers>
      </NodeContent>
    </BaseNodeWrapper>
  );
};

export default memo(ConditionalNode);

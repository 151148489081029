import { basicSetup, minimalSetup } from 'codemirror';
import { EditorView, keymap, lineNumbers } from '@codemirror/view';
import { EditorState } from '@codemirror/state';
import { indentWithTab } from '@codemirror/commands';
import { autocompletion } from '@codemirror/autocomplete';
import { linter, lintGutter } from '@codemirror/lint';
import { javascript, esLint } from '@codemirror/lang-javascript';
import { json, jsonParseLinter } from '@codemirror/lang-json';
import * as eslint from 'eslint-linter-browserify';
import { oneDark } from '@codemirror/theme-one-dark';
import { basicLight } from 'cm6-theme-basic-light';
import { preventModifyTargetRanges, smartDelete } from 'codemirror-readonly-ranges';
import globals from 'globals';

// TODO: avoid duplicate $
const completions = (suggestions) => (context) => {
  let before = context.matchBefore(/\w+/);
  if (!context.explicit && !before) return null;
  return {
    from: before ? before.from : context.pos,
    validFor: /^\w*$/,
    options: suggestions,
  };
};

export const default_suggestions = () => {
  let list = [];

  list = [
    ...list,
    ...[
      'api.bearer',
      'api.basic',
      'api.header',
      'await api.attach',
      'await api.get',
      'await api.post',
      'await api.patch',
      'await api.put',
      'await api.delete',
    ].map((label) => ({ label: `${label}()`, type: 'function' })),
  ];

  list = [
    ...list,
    ...[
      'recall',
      'await openid_access_token',
      'await remember',
      'await forget',
      'await clear_session_data',
      'await prompt_text',
      'await prompt_image',
      'await artifact_url',
    ].map((label) => ({ label: `${label}()`, type: 'function' })),
  ];

  list = [
    ...list,
    ...[
      'Utils.array_to_buffer',
      'Utils.buffer_to_md5',
      'Utils.clamp',
      'await Utils.fetch_metadata',
      'Utils.gql',
      'Utils.graphql_client',
      'await Utils.md5_url',
      'Utils.password',
      'Utils.rand',
      'Utils.rand_f',
      'Utils.remap',
      'Utils.sample',
      'Utils.set_deep',
      'Utils.shuffle',
      'Utils.sleep',
      'Utils.weighted_sample',
    ].map((label) => ({ label: `${label}()`, type: 'function' })),
  ];

  list = [
    ...list,
    ...[
      'Dates.parse',
      'Dates.format',
      'Dates.now',
      'Dates.today',
      'Dates.tomorrow',
      'Dates.yesterday',
      'Dates.future',
      'Dates.past',
      'Dates.far_future',
      'Dates.far_past',
      'Dates.whenever',
      'Dates.add',
      'Dates.sub',
      'Dates.min',
      'Dates.max',
      'Dates.add_now',
      'Dates.sub_now',
      'Dates.closest',
      'Dates.closest_to',
      'Dates.is_before',
      'Dates.is_after',
      'Dates.is_future',
      'Dates.is_past',
      'Dates.is_weekend',
    ].map((label) => ({ label: `${label}()`, type: 'function' })),
  ];

  list = [...list, ...['RUN_ID', 'ACTOR', 'SESSION_DATA', 'API', 'API_HOST'].map((label) => ({ label, type: 'variable' }))];

  list = [...list, ...['api', 'Utils', 'Dates'].map((label) => ({ label, type: 'class' }))];

  return list;
};

export const init_codemirror_text = (textarea, options) => {
  let initialized = document.querySelector(`#${textarea.dataset.initialized}`);
  if (initialized) initialized.remove();

  options ||= {};

  options.suggestions ||= [];

  options.source ||= textarea.value;

  options.decorator ||= (src) => src;

  options.on_update ||= (e) => {
    if (!e.docChanged) return;
    textarea.value = e.state.doc;
  };

  options.on_esc ||= () => {};

  options.theme ||= 'light';

  options.focus ||= { line: 1, position: 0 };

  if (!('wordwrap' in options)) options.wordwrap = true;

  const view = new EditorView({
    doc: options.decorator(options.source),
    extensions: [
      minimalSetup,
      autocompletion({ override: [completions(options.suggestions)] }),
      keymap.of([indentWithTab]),
      keymap.of([{ key: 'Escape', run: options.on_esc }]),
      options.wordwrap ? EditorView.lineWrapping : null,
      EditorState.readOnly.of(textarea.dataset.readonly),
      EditorView.updateListener.of(options.on_update),
      EditorView.editorAttributes.of({ class: options.className }),
      options.theme === 'dark' ? oneDark : basicLight,
    ],
  });

  if (!!textarea.dataset.readonly) view.dom.dataset.readonly = textarea.dataset.readonly;
  view.dom.id = `codemirror_${crypto.randomUUID()}`;
  view.dom.dataset.theme = options.theme;
  view.dom.style = textarea.dataset.style;

  textarea.style.display = 'none';
  textarea.insertAdjacentElement('afterend', view.dom);
  textarea.dataset.initialized = view.dom.id;

  if (!textarea.dataset.readonly) {
    view.focus();
    view.dispatch({ selection: { anchor: view.state.doc.line(options.focus.line).from + options.focus.position } });
  }

  return view;
};

export const init_codemirror_json = (textarea, options) => {
  let initialized = document.querySelector(`#${textarea.dataset.initialized}`);
  if (initialized) initialized.remove();

  const lintOptions = textarea.dataset.readonly ? [] : [lintGutter(), linter(jsonParseLinter())];

  options ||= {};

  options.suggestions ||= [];

  options.source ||= textarea.value;

  options.decorator ||= (src) => src;

  options.on_update ||= (e) => {
    if (!e.docChanged) return;
    textarea.value = e.state.doc;
  };

  options.on_esc ||= () => {};

  options.theme ||= 'light';

  options.focus ||= { line: 1, position: 0 };

  if (!('wordwrap' in options)) options.wordwrap = true;

  const view = new EditorView({
    doc: options.decorator(options.source),
    extensions: [
      textarea.dataset.readonly ? minimalSetup : basicSetup,
      autocompletion({ override: [completions(options.suggestions)] }),
      keymap.of([indentWithTab]),
      keymap.of([{ key: 'Escape', run: options.on_esc }]),
      json({}),
      ...lintOptions,
      options.wordwrap ? EditorView.lineWrapping : null,
      EditorState.readOnly.of(textarea.dataset.readonly),
      EditorView.updateListener.of(options.on_update),
      EditorView.editorAttributes.of({ class: options.className }),
      options.theme === 'dark' ? oneDark : basicLight,
    ].filter(Boolean),
  });

  if (!!textarea.dataset.readonly) view.dom.dataset.readonly = textarea.dataset.readonly;
  view.dom.id = `codemirror_${crypto.randomUUID()}`;
  view.dom.dataset.theme = options.theme;
  view.dom.style = textarea.dataset.style;

  textarea.style.display = 'none';
  textarea.insertAdjacentElement('afterend', view.dom);
  textarea.dataset.initialized = view.dom.id;

  if (!textarea.dataset.readonly) {
    view.focus();
    view.dispatch({ selection: { anchor: view.state.doc.line(options.focus.line).from + options.focus.position } });
  }

  return view;
};

export const init_codemirror_javascript = (textarea, options) => {
  let initialized = document.querySelector(`#${textarea.dataset.initialized}`);
  if (initialized) initialized.remove();

  options ||= {};

  options.suggestions ||= default_suggestions();

  options.source ||= textarea.value;

  options.decorator ||= (src) => `(async () => {\n${src || '  '}\n})();`;

  options.on_update ||= (e) => {
    if (!e.docChanged) return;
    const lines = e.state.doc.toString().split(/\r?\n/);
    lines.shift();
    lines.pop();
    textarea.value = lines.join('\n');
  };

  options.on_esc ||= () => {};

  options.theme ||= 'dark';

  options.focus ||= { line: 2, position: 2 };

  if (!('wordwrap' in options)) options.wordwrap = true;

  const readonlyRange = (targetState) => [
    { from: undefined, to: targetState.doc.line(1).to },
    { from: targetState.doc.line(targetState.doc.lines).from, to: undefined },
  ];

  const view = new EditorView({
    doc: options.decorator(options.source),
    extensions: [
      basicSetup,
      autocompletion({ override: [completions(options.suggestions)] }),
      keymap.of([indentWithTab]),
      keymap.of([{ key: 'Escape', run: options.on_esc }]),
      javascript({}),
      lineNumbers({ formatNumber: (n) => n - 1 }),
      lintGutter(),
      linter(
        esLint(new eslint.Linter(), {
          languageOptions: {
            parserOptions: { ecmaVersion: 'latest' },
            globals: {
              ...globals.node,
              ...options.suggestions.reduce((a, v) => ({ ...a, [v.label.replace('await ', '').replace('()', '')]: 'readonly' }), {}),
            },
          },
          rules: {
            semi: ['error', 'always'],
            'no-undef': ['error'],
            'no-global-assign': ['error'],
          },
        }),
      ),
      options.wordwrap ? EditorView.lineWrapping : null,
      smartDelete(readonlyRange),
      preventModifyTargetRanges(readonlyRange),
      EditorView.updateListener.of(options.on_update),
      EditorView.editorAttributes.of({ class: options.className }),
      options.theme === 'light' ? basicLight : oneDark,
    ],
  });

  view.dom.id = `codemirror_${crypto.randomUUID()}`;
  view.dom.dataset.readonlyFirstLast = true;
  view.dom.dataset.theme = options.theme;
  view.dom.style = textarea.dataset.style;

  textarea.style.display = 'none';
  textarea.insertAdjacentElement('afterend', view.dom);
  textarea.dataset.initialized = view.dom.id;

  view.focus();
  view.dispatch({ selection: { anchor: view.state.doc.line(options.focus.line).from + options.focus.position } });

  return view;
};

document.addEventListener('turbo:load', () => {
  document.querySelectorAll("textarea[data-codemirror='text']").forEach((el) => init_codemirror_text(el));
  document.querySelectorAll("textarea[data-codemirror='json']").forEach((el) => init_codemirror_json(el));
  document.querySelectorAll("textarea[data-codemirror='javascript']").forEach((el) => init_codemirror_javascript(el));
});

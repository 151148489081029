import React, { memo, useCallback } from 'react';
import { useReactFlow } from 'reactflow';
import styled from 'styled-components';

import { NODE_DEFAULTS } from '../index';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 3px;
  background: var(--color-white);
  color: var(--color-black);
  width: 140px;
  box-shadow: var(--shadow-normal);
`;

const LibraryButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-size: 11px;
  font-family: var(--font-monospace);
  font-weight: var(--weight-semibold);
  line-height: normal;
  letter-spacing: 0.44px;
  text-transform: capitalize;
  width: 100%;
  overflow: hidden;
  padding: 11px 16px;
  cursor: pointer;
  border-bottom: 1px solid var(--color-grey-20);

  &:last-child {
    border-bottom: none;
  }

  .swatch {
    width: 12px;
    height: 12px;
    border-radius: 1px;
    background: var(--node-title-fill-color);
  }
`;

const Library = ({ nodeTypes }) => {
  const { addNodes, screenToFlowPosition, getViewport } = useReactFlow();
  const viewport = getViewport();

  const onDragStart = (type) =>
    useCallback((event) => {
      event.dataTransfer.setData('application/reactflow', type);
      event.dataTransfer.effectAllowed = 'move';
    }, []);

  const onClick = (type) =>
    useCallback(
      (event) => {
        event.preventDefault();
        const rect = document.querySelector('.react-flow').getBoundingClientRect();
        const position = screenToFlowPosition({ x: rect.top + rect.width * 0.5, y: rect.top + rect.height * 0.5 });
        addNodes([{ id: `node:${crypto.randomUUID()}`, data: {}, type, position, ...NODE_DEFAULTS }]);
      },
      [viewport],
    );

  return (
    <Container>
      {Object.keys(nodeTypes)
        .filter((x) => x !== 'entry' && x !== 'default')
        .map((type) => {
          let name = type.replace('_', ' ');

          if (type == 'custom_code') {
            name = 'code';
          } else if (type == 'conditional') {
            name = 'if/else';
          } else if (type == 'api') {
            name = 'API';
          }

          return (
            <LibraryButton key={type} className={`node-${type}`} onClick={onClick(type)} onDragStart={onDragStart(type)} draggable>
              <div className="swatch" />
              {name}
            </LibraryButton>
          );
        })}
    </Container>
  );
};

export default memo(Library);

import React from 'react';
import styled from 'styled-components';

import Plus from '../../../../assets/images/icons/plus.svg';

const Button = styled.button`
  cursor: pointer;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: ${(props) => (props.$inHeader ? '50%' : '')};
  right: ${(props) => (props.$inHeader ? 'calc(var(--h-padding) - 2px)' : '')};
  transform: ${(props) => (props.$inHeader ? 'translateY(-50%)' : '')};

  --icon-color: var(--node-handle-inactive);
  &:hover {
    --icon-color: var(--node-handle-active);
  }
`;

const AddButton = ({ inHeader, onClick }) => (
  <Button $inHeader={inHeader} type="button" onClick={onClick}>
    <Plus />
  </Button>
);

export default AddButton;

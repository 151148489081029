import React, { memo, useEffect, useCallback } from 'react';
import { Position, useNodeId, useUpdateNodeInternals, useReactFlow } from 'reactflow';

import ConnectableHandle from '../Shared/ConnectableHandle';
import NameEditor from '../Shared/NameEditor';
import useNameEditor from '../Shared/useNameEditor';

import { BaseNodeWrapper, Header, NodeContent, PaddedGroup } from '../Shared/StyledComponents';

const ExtractNode = ({ data }) => {
  const nodeId = useNodeId();
  const nameEditor = useNameEditor();
  const updateNodeInternals = useUpdateNodeInternals();
  const { setNodes } = useReactFlow();

  useEffect(() => {
    // generate new blank ids
    const input = data.input || `input:${crypto.randomUUID()}`;
    const output = data.output || `output:${crypto.randomUUID()}`;
    const key = data.key || '{{input}}';

    // update node.data
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) node.data = { ...node.data, input, output, key };
        return node;
      }),
    );

    // refresh internal handles
    updateNodeInternals(nodeId);
  }, []);

  const updateDataField = useCallback(
    (field) => (event) => {
      // update handle in node.data
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === nodeId) node.data[field] = event.target.value;
          return node;
        }),
      );

      // refresh internal handles
      updateNodeInternals(nodeId);
    },
    [updateNodeInternals],
  );

  return (
    <BaseNodeWrapper className="node-extract">
      <Header data-drag-handle>
        <ConnectableHandle type="target" id={data.input} position={Position.Left} inHeader={true} />
        <NameEditor parent="extract" placeholder="extract" value={data.name} onChange={(newName) => nameEditor.updateName(newName)} />

        <ConnectableHandle
          type="source"
          id={data.output}
          position={Position.Right}
          limit={{ key: 'sourceHandle', id: data.output, limit: 1 }}
          inHeader={true}
        />
      </Header>
      <NodeContent>
        <PaddedGroup>
          <input type="text" className="input w-full" placeholder="{{input}}" defaultValue={data.key} onChange={updateDataField('key')} />
        </PaddedGroup>
      </NodeContent>
    </BaseNodeWrapper>
  );
};

export default memo(ExtractNode);

import { useNodeId, useUpdateNodeInternals, useReactFlow } from 'reactflow';
import { useCallback } from 'react';

function useNameEditor() {
  const updateNodeInternals = useUpdateNodeInternals();
  const nodeId = useNodeId();
  const { setNodes } = useReactFlow();

  const updateName = (newName) => updateField('name', newName);

  // General single field update function
  const updateField = useCallback(
    (key, newValue) => {
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === nodeId) node.data = { ...node.data, [key]: newValue };
          return node;
        }),
      );
      updateNodeInternals(nodeId);
    },
    [updateNodeInternals],
  );

  return { updateName, updateField };
}

export default useNameEditor;

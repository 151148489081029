import { Controller } from '@hotwired/stimulus';
import OpenAPIParser from '@readme/openapi-parser';

// Connects to data-controller="media-parse-openapi-spec"
export default class extends Controller {
  static values = { url: String };
  static targets = ['suggestedServers'];

  async connect() {
    if (this.element.dataset.initialized) return;
    this.element.dataset.initialized = true;

    if (!this.urlValue) return;

    let api;

    try {
      api = await OpenAPIParser.validate(this.urlValue, {
        resolve: { external: false, circular: 'ignore' },
        validate: { schema: false, spec: false },
      });
      console.log(api);
    } catch (err) {
      console.error('ignoring error parsing openapi_spec', err);
      return;
    }

    if (this.hasSuggestedServersTarget) {
      const servers = api.servers || [];

      if (api.host && api.protocol)
        servers.push({ url: [api.protocol, '://', api.host, (api.basePath || '/').replace(/\/$/, '')].join('') });

      servers.forEach((server) => {
        if (!server.url || server.url.includes('://localhost')) return;
        const suggestion = document.createElement('div');
        suggestion.innerText = server.url;
        suggestion.className = 'suggestion mono';
        suggestion.dataset.inputSuggestionTarget = 'suggestion';
        this.suggestedServersTarget.appendChild(suggestion);
      });
    }
  }
}

import React, { memo, useState, useEffect, useCallback } from 'react';
import { Position, useNodeId, useUpdateNodeInternals, useReactFlow } from 'reactflow';
import styled from 'styled-components';

import ConnectableHandle from '../Shared/ConnectableHandle';
import NameEditor from '../Shared/NameEditor';
import useNameEditor from '../Shared/useNameEditor';
import AffixedInput from '../Shared/AffixedInput';
import Deletable from '../Shared/Deleteable';
import AddButton from '../Shared/AddButton';
import NiceCheckbox from '../Shared/NiceCheckbox';

import { BaseNodeWrapper, Header, NodeContent, PaddedGroup } from '../Shared/StyledComponents';

const Footer = styled.div`
  display: flex;
  font-family: var(--font-monospace);
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 11px;
  color: var(--color-grey-60);
  margin-top: 16px;
  margin-bottom: 4px;
`;

const SplitRow = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const FieldPair = styled.div`
display: flex;
margin-top: 8px;
&.first {
  margin-top: 0;
}

/* A shadow like we usually use for focus indication wasn't working in this context, so we'l use this trick.
Can't use this generally, since <inputs> can't support pseudo-elements. */
&:focus-within {
  position: relative;
  &:before {
    position: absolute;
    inset: 0;
    content: '';
    border: 1px solid var(--focus-color);
    z-index: 1;
  }
}


.handle-holder {
  width: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.qty {
  border-right: 1px solid var(--color-grey-30);
  width: 140px;
}
.pct {
  text-align: center;
}

input {
  &:focus {
    box-shadow: 0 0 !important;
  }
}


}`;

const SplitNode = ({ data }) => {
  const nodeId = useNodeId();
  const nameEditor = useNameEditor();
  const updateNodeInternals = useUpdateNodeInternals();
  const { setEdges, setNodes } = useReactFlow();

  const [outputs, setOutputs] = useState(data.outputs || []);

  useEffect(() => {
    // generate new blank ids
    const input = data.input || `input:${crypto.randomUUID()}`;

    // generate initial outputs, if empty
    let outputs = data.outputs || [];

    if (outputs.length === 0) {
      outputs = [
        { id: `output:${crypto.randomUUID()}`, weight: 50 },
        { id: `output:${crypto.randomUUID()}`, weight: 50 },
      ];
      setOutputs(outputs);
    }

    // update node.data
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) node.data = { ...node.data, input, outputs };
        return node;
      }),
    );

    // refresh internal handles
    updateNodeInternals(nodeId);
  }, []);

  const addOutput = useCallback(() => {
    // generate new output, add it
    const output = { id: `output:${crypto.randomUUID()}`, weight: 0 };
    setOutputs((rows) => [...rows, output]);

    // update node.data
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) {
          node.data = { ...node.data, outputs: [...(node.data?.outputs || []), output] };
        }
        return node;
      }),
    );

    // refresh internal handles
    updateNodeInternals(nodeId);
  }, [updateNodeInternals]);

  const removeOutput = useCallback(
    (id) => () => {
      // remove handle
      setOutputs((rows) => [...rows.filter((row) => row.id !== id)]);

      // remove handle from node.data
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === nodeId) {
            node.data = { ...node.data, outputs: [...node.data.outputs].filter((row) => row.id !== id) };
          }
          return node;
        }),
      );

      // remove any edges connected to output
      setEdges((eds) => eds.filter((e) => e.sourceHandle !== id));

      // refresh internal handles
      updateNodeInternals(nodeId);
    },
    [updateNodeInternals],
  );

  const updateDataField = useCallback(
    (field) => (event) => {
      // update handle in node.data
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === nodeId) {
            let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
            node.data[field] = value;
          }

          return node;
        }),
      );

      // refresh internal handles
      updateNodeInternals(nodeId);
    },
    [updateNodeInternals],
  );

  const updateOutput = useCallback(
    (id, field, refresh) => (event) => {
      // update handle in node.data
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === nodeId) {
            // select output
            const outputs = node.data.outputs;
            const i = outputs.findIndex((row) => row.id == id);

            let value = event.target.value;

            if (field === 'weight') {
              // validate is number
              value = parseInt(value);
              if (isNaN(value)) value = outputs[i][field];

              // validate in range 0 - 100
              value = Math.min(100, Math.max(0, value));

              // validate within remainder
              const total = outputs.filter((output) => output.id !== id).reduce((acc, output) => acc + parseInt(output.weight) || 0, 0);
              value = Math.min(100 - total, value);
            }

            // update node.data
            outputs[i][field] = value;
            node.data = { ...node.data, outputs };

            // refresh field
            if (refresh) event.target.value = value;
          }

          return node;
        }),
      );

      // refresh internal handles
      updateNodeInternals(nodeId);
    },
    [updateNodeInternals],
  );

  return (
    <BaseNodeWrapper className="node-split">
      <Header data-drag-handle>
        <ConnectableHandle type="target" id={data.input} position={Position.Left} inHeader={true} />
        <NameEditor parent="split" placeholder="split" value={data.name} onChange={(newName) => nameEditor.updateName(newName)} />
        <AddButton inHeader={true} onClick={addOutput} />
      </Header>
      <NodeContent>
        {outputs.map((output, index) => (
          <Deletable
            rowClassName=" row"
            onDeleteClick={removeOutput(output.id)}
            key={output.id}
            xButtonLeft="-24px"
            xButtonTop={index === 0 ? '9px' : '17px'}
          >
            <PaddedGroup>
              <SplitRow>
                <FieldPair className={index == 0 ? 'first' : ''}>
                  <input
                    type="text"
                    className="input qty"
                    placeholder="option"
                    defaultValue={output.name}
                    onChange={updateOutput(output.id, 'name')}
                  />
                  <AffixedInput
                    decorator={'%'}
                    placement={'suffix'}
                    buffer={7}
                    type="text"
                    className="input weight pct"
                    placeholder="0"
                    defaultValue={output.weight}
                    onChange={updateOutput(output.id, 'weight')}
                    onBlur={updateOutput(output.id, 'weight', true)}
                  />
                </FieldPair>

                <ConnectableHandle
                  type="source"
                  id={output.id}
                  position={Position.Right}
                  wrapperStyle={{ transform: 'none', position: 'static' }}
                  limit={{ key: 'sourceHandle', id: output.id, limit: 1 }}
                />
              </SplitRow>
            </PaddedGroup>
          </Deletable>
        ))}
        <PaddedGroup>
          <Footer>
            <NiceCheckbox id={`overwrite-${data.input}`} defaultChecked={data.overwrite} onChange={updateDataField('overwrite')} />
            <label htmlFor={`overwrite-${data.input}`}>Overwrite output value</label>
          </Footer>
        </PaddedGroup>
      </NodeContent>
    </BaseNodeWrapper>
  );
};

export default memo(SplitNode);

import React from 'react';
import { Buttonish } from '../Shared/StyledComponents';

import CodeSnippet from '../../../../assets/images/icons/snippet-code.svg';
import TextSnippet from '../../../../assets/images/icons/snippet-text.svg';
import JsonSnippet from '../../../../assets/images/icons/snippet-json.svg';

const Button = ({ onClick, icon, label, disabled = false, decorative = false, extraStyle = {} }) => {
  const inlineStyle = {
    ...extraStyle,
    pointerEvents: decorative ? 'none' : 'auto',
  };

  return (
    <Buttonish
      disabled={disabled}
      type="button"
      onClick={onClick}
      style={inlineStyle}
      aria-hidden={decorative}
      className={`${decorative ? 'textarea-label-decorative' : ''}`}
    >
      {icon && (
        <i style={{ display: 'flex', alignItems: 'center' }}>
          {icon === 'code' && <CodeSnippet />}
          {icon === 'text' && <TextSnippet />}
          {icon === 'json' && <JsonSnippet />}
        </i>
      )}
      {label}
    </Buttonish>
  );
};

export default Button;

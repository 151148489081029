import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="clipboard"
export default class extends Controller {
  static targets = ['source'];

  connect() {
    // autolink clipboard-input
    if (this.element.classList.contains('clipboard-input')) {
      this.element.addEventListener('click', (e) => this.copy(e));
    }
  }

  // fire manually via data-action="clipboard#copy"
  copy(event) {
    event.preventDefault();

    // copy source or element innerText
    navigator.clipboard.writeText(this.hasSourceTarget ? this.sourceTarget.innerText : this.element.innerText);

    // pulse flash class
    this.element.classList.add('clipboard-flash');
    setTimeout(() => this.element.classList.remove('clipboard-flash'), 250);
  }
}

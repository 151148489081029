import React, { memo, useEffect, useCallback } from 'react';
import { Position, useNodeId, useUpdateNodeInternals, useReactFlow } from 'reactflow';

import ConnectableHandle from '../Shared/ConnectableHandle';
import NameEditor from '../Shared/NameEditor';
import useNameEditor from '../Shared/useNameEditor';

import { BaseNodeWrapper, Header, Dropdown, EventSplitRow, NodeContent, PaddedGroup } from '../Shared/StyledComponents';

const TakeNode = ({ data }) => {
  const nodeId = useNodeId();
  const updateNodeInternals = useUpdateNodeInternals();
  const { setNodes } = useReactFlow();
  const nameEditor = useNameEditor();

  useEffect(() => {
    // generate initial values
    const input = data.input || `input:${crypto.randomUUID()}`;
    const output = data.output || `output:${crypto.randomUUID()}`;
    const from = data.from || 'last';
    const quantity = 'quantity' in data ? data.quantity : '1';

    // update node.data
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) node.data = { ...node.data, input, output, from, quantity };
        return node;
      }),
    );

    // refresh internal handles
    updateNodeInternals(nodeId);
  }, []);

  const updateDataField = useCallback(
    (field) => (event) => {
      // update handle in node.data
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === nodeId) node.data = { ...node.data, [field]: event.target.value };
          return node;
        }),
      );

      // refresh internal handles
      updateNodeInternals(nodeId);
    },
    [updateNodeInternals],
  );

  return (
    <BaseNodeWrapper className="node-take">
      <Header data-drag-handle>
        <ConnectableHandle type="target" id={data.input} position={Position.Left} inHeader={true} />
        <NameEditor parent="take" placeholder="take" value={data.name} onChange={(newName) => nameEditor.updateName(newName)} />
        <ConnectableHandle
          type="source"
          id={data.output}
          position={Position.Right}
          limit={{ key: 'sourceHandle', id: data.output, limit: 1 }}
          inHeader={true}
        />
      </Header>
      <NodeContent>
        <PaddedGroup>
          <EventSplitRow>
            <Dropdown className="transparent" value={data.from} onChange={updateDataField('from')}>
              <option value="first">First</option>
              <option value="last">Last</option>
              <option value="random">Random</option>
            </Dropdown>

            <input
              type="number"
              style={{ textAlign: 'center' }}
              className="input"
              placeholder="1"
              defaultValue={data.quantity}
              onInput={updateDataField('quantity')}
            />
          </EventSplitRow>
        </PaddedGroup>
      </NodeContent>
    </BaseNodeWrapper>
  );
};

export default memo(TakeNode);

import { Controller } from '@hotwired/stimulus';
import { PREDICATES } from '../utils';

// Connects to data-controller="media-data-conditional"
export default class extends Controller {
  static values = { url: String };
  static targets = ['table', 'predicate', 'leftTemplate', 'rightTemplate', 'left', 'right'];

  async connect() {
    if (this.element.dataset.initialized) return;
    this.element.dataset.initialized = true;

    if (!this.urlValue) return;
    const response = await fetch(this.urlValue, { cache: 'no-cache' });
    let data = response.ok ? await response.json() : {};

    if (Array.isArray(data.left)) {
      this.leftTemplateTarget.innerText = data.left[0];
      this.leftTarget.innerText = data.left[1];
    }

    if (Array.isArray(data.right)) {
      this.rightTemplateTarget.innerText = data.right[0];
      this.rightTarget.innerText = data.right[1];
    }

    if (data.predicate) {
      this.predicateTargets.forEach((el) => (el.innerText = PREDICATES[data.predicate] || data.predicate));
    }

    this.tableTarget.style.display = null;
  }
}

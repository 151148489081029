import { Controller } from '@hotwired/stimulus';
import { init_codemirror_json, init_codemirror_text } from '../components/codemirror';

// Connects to data-controller="media-data-embed"
export default class extends Controller {
  static targets = ['textarea'];
  static values = {
    url: String,
    plaintext: Boolean,
    expandMemoryDump: Boolean,
  };

  async connect() {
    if (this.element.dataset.initialized) return;
    this.element.dataset.initialized = true;

    const response = await fetch(this.urlValue, { cache: 'no-cache' });

    const data = this.plaintextValue ? (response.ok ? await response.text() : '') : response.ok ? await response.json() : {};

    // process data.memory_dump
    if (this.expandMemoryDumpValue) {
      data.memory_dump ||= {};
      if (!data.memory) {
        data.memory = Object.fromEntries(
          Object.entries(data.memory_dump.custom || {})
            .filter(([_, v]) => !v.expired)
            .map(([k, v]) => [k, v.value]),
        );
      }

      if (!data.session_data) {
        data.session_data = Object.fromEntries(
          Object.entries(data.memory_dump.session_data || {})
            .filter(([_, v]) => !v.expired)
            .map(([k, v]) => [v.user_key || k, v.value]),
        );
      }

      delete data.memory_dump;
    }

    if (this.plaintextValue) {
      this.textareaTarget.value = data;
      init_codemirror_text(this.textareaTarget);
    } else {
      this.textareaTarget.value = JSON.stringify(data, null, 2);
      init_codemirror_json(this.textareaTarget);
    }
  }
}

import React, { forwardRef } from 'react';
import styled from 'styled-components';

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute !important;
  opacity: 0 !important;
`;

const Base = styled.div`
  cursor: pointer;
  width: 12px;
  height: 12px;
  border-radius: 2px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-grey-40);
  &:hover {
    background-color: var(--color-grey-50);
  }
  svg {
    display: none;
  }
  ${HiddenCheckbox}:checked + & {
    background-color: var(--color-blue-60);
    svg {
      display: block;
    }
  }
`;

const NiceCheckbox = forwardRef(({ className = '', ...props }, ref) => {
  return (
    <label>
      <HiddenCheckbox type="checkbox" ref={ref} {...props} />
      <Base>
        {/* TODO: move to .svg file */}
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 8" fill="none">
          <path d="M6.66732 2L3.00065 5.66667L1.33398 4" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </Base>
    </label>
  );
});

NiceCheckbox.displayName = 'NiceCheckbox';

export default NiceCheckbox;

import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="toggle-tab"
export default class extends Controller {
  static classes = ['btnInactive', 'btnActive'];
  static targets = ['tab', 'btn'];
  static values = { default: String };

  connect() {
    this.btnTargets.forEach((btn) => btn.addEventListener('click', (e) => this.toggle(btn.dataset.target, e)));
    this.toggle(this.hasDefaultValue ? this.defaultValue : this.btnTargets[0].dataset.target);
  }

  toggle(active, event) {
    if (event) event.preventDefault();

    this.tabTargets.filter((tab) => tab.dataset.name !== active).forEach((tab) => (tab.style.display = 'none'));
    this.tabTargets.filter((tab) => tab.dataset.name === active).forEach((tab) => (tab.style.display = null));

    this.btnTargets.filter((btn) => btn.dataset.target !== active).forEach((btn) => (btn.className = this.btnInactiveClasses.join(' ')));
    this.btnTargets.filter((btn) => btn.dataset.target === active).forEach((btn) => (btn.className = this.btnActiveClasses.join(' ')));
  }
}

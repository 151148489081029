import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="input-suggestion"
export default class extends Controller {
  static targets = ['input', 'suggestion'];

  suggestionTargetConnected(target) {
    target.addEventListener('click', () => (this.inputTarget.value = target.innerText));
  }
}

import { Controller } from '@hotwired/stimulus';
import consumer from '../channels/consumer';
import LocalTime from 'local-time';

// Connects to data-controller="blueprint-status"
export default class extends Controller {
  static targets = ['button', 'processing', 'timestamp'];

  static values = { id: String };

  connect() {
    this.channel = consumer.subscriptions.create(
      { channel: 'BlueprintsChannel', id: this.idValue },
      { received: this.received.bind(this) },
    );
  }

  disconnect() {
    if (this.channel) this.channel.unsubscribe();
  }

  received(data) {
    if (data.status == 'processing') {
      if (this.hasButtonTarget) this.buttonTarget.style.display = 'none';
      if (this.hasProcessingTarget) this.processingTarget.style.display = null;
    } else if (data.status == 'ready') {
      if (this.hasButtonTarget) this.buttonTarget.style.display = null;
      if (this.hasProcessingTarget) this.processingTarget.style.display = 'none';
    }

    if (data.timestamp && this.hasTimestampTarget) {
      this.timestampTarget.setAttribute('datetime', data.timestamp);
      this.timestampTarget.removeAttribute('data-localized');
      LocalTime.run();
    }
  }
}

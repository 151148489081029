import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="dropzone"
export default class extends Controller {
  static targets = ['input', 'hidden', 'preview', 'publicUrl', 'filename'];

  get csrf() {
    return document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  }

  get create_media_url() {
    return this.data.get('create-media-url');
  }

  get max_file_size() {
    return 1024 * 1024 * 1; // mb
  }

  async create_media(file) {
    const headers = {
      'Content-Type': 'application/json',
      'X-CSRF-Token': this.csrf,
    };

    const body = {
      type: file.type,
      size: file.size,
      name: file.name,
    };

    let response = await fetch(this.create_media_url, { method: 'POST', headers, body: JSON.stringify(body) });
    if (response.status !== 200) return response;
    return await response.json();
  }

  async upload_media(file, presigned_upload_url) {
    const headers = {
      'content-type': file.type,
    };

    let response = await fetch(presigned_upload_url, { method: 'PUT', headers, body: file });
    if (response.status !== 200) return response;
    return true;
  }

  async prepare_media(id) {
    const headers = { 'X-CSRF-Token': this.csrf, 'X-Dropzone-Controller': true };

    let response = await fetch(`${this.create_media_url}/${id}`, { method: 'PATCH', headers });
    if (response.status !== 200) return response;
    return await response.json();
  }

  file_is_valid(file) {
    if (!file) return false;
    if (file.size > this.max_file_size) return false;
    return true;
  }

  async connect() {
    if (!this.hasInputTarget) return;

    this.inputTarget.addEventListener('change', async (e) => {
      e.preventDefault();

      if (this.inputTarget.files.length === 0) {
        console.warn('no file selected');
        return;
      }

      const file = this.inputTarget.files[0];

      if (!this.file_is_valid(file)) {
        console.warn('file is too large');
        return;
      }

      const media = await this.create_media(file);
      console.log({ media });
      const presigned_upload_url = media.data.presigned_upload_url;

      if (!presigned_upload_url) {
        console.warn('create_media failed');
        return;
      }

      const upload = await this.upload_media(file, presigned_upload_url);
      console.log({ upload });

      if (!upload) {
        console.warn('upload_media failed');
        return;
      }

      const prepare = await this.prepare_media(media.data.id);
      console.log({ prepare });

      if (!prepare) {
        console.warn('prepare_media failed');
        return;
      }

      this.hiddenTarget.value = prepare.data.id;
      this.hiddenTarget.dispatchEvent(new Event('change'));

      if (this.hasPreviewTarget) {
        this.previewTarget.style.display = null;
        this.previewTarget.src = prepare.data.public_url;
      }

      if (this.hasPublicUrlTarget) {
        this.publicUrlTarget.innerText = prepare.data.public_url;
      }

      if (this.hasFilenameTarget) {
        this.filenameTarget.innerText = file.name;
        this.filenameTarget.dataset.present = true;
      }
    });
  }
}

import React, { memo, useState, useEffect, useRef, useCallback } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { init_codemirror_javascript, init_codemirror_json, init_codemirror_text, default_suggestions } from '../../codemirror';

const CodemirrorModal = ({ details, onClose, onChange }) => {
  details ||= {};
  details.field ||= 'source';

  const { node, mode, field } = details;
  const [name, setName] = useState(node.data.name);

  let untitled = 'untitled';
  if (node.type == 'api') untitled = 'API';
  if (node.type == 'prompt_text' || node.type == 'prompt_image') untitled = 'prompt';

  const textareaRef = useRef(null);

  useHotkeys('esc', onClose, { preventDefault: true });

  useEffect(() => {
    const options = {
      source: node.data[field],
      on_esc: onClose,
      on_update: (e) => {
        if (!e.docChanged) return;
        const lines = e.state.doc.toString().split(/\r?\n/);

        if (mode === 'javascript') {
          lines.shift();
          lines.pop();
        }

        node.data[field] = lines.join('\n');
        textareaRef.current.value = node.data[field];
        onChange(node.data);
      },
      theme: 'dark',
    };

    if (mode === 'javascript' || mode === 'json') {
      options.suggestions = [...default_suggestions(), ...['input'].map((label) => ({ label, type: 'variable' }))];
    }

    if (mode === 'javascript') {
      options.decorator = (src) => `(async (input) => {\n${src || '  '}\n})();`;

      if (field == 'payload') {
        options.decorator = (src) => `const payload = {\n${src || '  '}\n};`;

        options.on_update = (e) => {
          if (!e.docChanged) return;
          const lines = e.state.doc.toString().split(/\r?\n/);
          lines[0] = '{';
          lines[lines.length - 1] = '}';
          node.data[field] = lines.join('\n');
          textareaRef.current.value = node.data[field];
          onChange(node.data);
        };

        const lines = options.source.split(/\r?\n/);

        // convert single line json to multiline
        if (lines.length === 1) {
          lines[0] = lines[0].substring(lines[0].indexOf('{') + 1, lines[0].lastIndexOf('}'));
          lines.unshift('{');
          lines.push('}');
        }

        lines.shift();
        lines.pop();
        options.source = lines.join('\n');
      }
    }

    switch (mode) {
      case 'javascript':
        init_codemirror_javascript(textareaRef.current, options);
        break;
      case 'json':
        init_codemirror_json(textareaRef.current, options);
        break;
      case 'text':
        init_codemirror_text(textareaRef.current, options);
        break;
    }
  }, [node, mode, field, textareaRef, onChange]);

  const updateName = useCallback(() => {
    let rename = prompt('Title', node.data.name || '');
    if (rename === null) return;
    node.data.name = rename;
    setName(rename);
    onChange(node.data);
  }, [name, setName, onChange]);

  return (
    <div className="modal">
      <div className="header">
        <span className={`name editable ${name ? '' : 'unnamed'}`} onClick={updateName}>
          {name || untitled}
        </span>
        <div className="close" onClick={onClose} />
      </div>
      <div className="content" data-mode={mode}>
        <textarea ref={textareaRef} />
      </div>
    </div>
  );
};

export default memo(CodemirrorModal);

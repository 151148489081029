import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="actor-pulse-chart"
export default class extends Controller {
  static values = {
    id: String,
    theta: Number,
    pulse: Number,
  };

  connect() {
    let theta = this.thetaValue;
    let label = 64;

    this.interval = setInterval(() => {
      const chartkick = Chartkick.charts[this.idValue];
      if (!chartkick) return;

      chartkick.chart.data.datasets[0].data.shift();
      chartkick.chart.data.labels.shift();

      chartkick.chart.data.datasets[0].data.push(Math.sin((theta += this.pulseValue * 8)));
      chartkick.chart.data.labels.push(label++);

      chartkick.chart.update();
    }, 100);
  }

  disconnect() {
    clearInterval(this.interval);
  }
}

import React from 'react';
import styled from 'styled-components';

import FilledX from '../../../../assets/images/icons/x-filled.svg';

const DeletableWrapper = styled.div`
  position: relative;

  &:hover .delete-button {
    opacity: 1;
  }
`;

const DeleteButton = styled.button`
  position: absolute;
  /* Let's just assume top/left will be altered on a case by case basis */
  top: 0;
  left: 0;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: -10px;
    bottom: -10px;
  }

  &:hover {
    opacity: 1;
  }
`;

const Deletable = ({ children, onDeleteClick, xButtonTop, xButtonLeft, className = '' }) => {
  return (
    <DeletableWrapper className={className}>
      <DeleteButton className="delete-button" onClick={onDeleteClick} style={{ top: xButtonTop, left: xButtonLeft }}>
        <FilledX />
      </DeleteButton>
      {children}
    </DeletableWrapper>
  );
};

export default Deletable;
